<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <!-- <div class="headline">
          <h4 class="page-title text-primary">Benachrichtigung an alle Benutzer</h4>
        </div> -->
        <v-tabs v-model="broadcastTab">
            <v-tab href="#tab-1">
              <div class="page-leftheader">
                <div class="form-label">
                  <h4 class="page-title text-primary">Broadcast erstellen</h4>
                </div>
              </div>
            </v-tab>

            <v-tab href="#tab-2">
              <div class="page-leftheader">
                <div class="form-label">
                  <h4 class="page-title text-primary">Historie</h4>
                </div>
              </div>
            </v-tab>
          </v-tabs>
      </div>
    </div>
    <v-tabs-items v-model="broadcastTab">
      <v-tab-item :value="'tab-1'">
        <div>
      <div class="card p-5">
          <div class="form-group">
              <label class="form-label">Deutsch</label>
              <input class="form-control" type="text" v-model="message.de" v-bind:class="{'is-invalid': emptyValidation && (message.de == null || message.de == '')}">
          </div>
          <div class="form-group">
              <label class="form-label">Englisch</label>
              <input class="form-control" type="text" v-model="message.en" v-bind:class="{'is-invalid': emptyValidation && (message.en == null || message.en == '')}">
          </div>
          <div class="form-group">
            <a class="btn btn-primary" @click="confirmDialog = true">Abschicken</a>
          </div>
      </div>
    </div>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Message</th>
                  <th>Erreichte Nutzer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="notif in notifications" :key="notif._id">
                  <td class="align-middle">{{ $formatDate(notif.createdAt) }}</td>
                  <td class="align-middle tableAbout">{{ notif.message.de }}</td>
                  <td class="align-middle">{{ notif.usersNotified }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="confirmDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Bestätigen</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Möchten sie diese Nachricht an alle Benutzer schicken?
        </v-card-text>
        <v-card-text v-show="showSendingLoadingCircle">
          Sendevorgang gestartet. Lassen Sie das Browserfenster während des Sendens gern geöffnet.<br>
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-secondary btn-pill mr-2" @click="confirmDialog = false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="sendNotification()">Ja</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Absenden müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 1,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,

      message: {
          de: "",
          en: "",
      },
      emptyDialog: false,
      confirmDialog: false,
      emptyValidation: false,
      showSendingLoadingCircle: false,
      broadcastTab: null,
      notifications: []
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    
    this.loadNotifications();
  },

  methods: {
    submitNotification() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();
      Vue.nextTick(() => {
            if(document.getElementsByClassName('is-invalid').length > 0) {
              this.emptyDialog = true;
              return;
            }
            _this.submitNotification();
      });  
    },
    sendNotification() {
        let _this = this;
        const body = { messages: this.message };
        this.showSendingLoadingCircle = true;
        this.$axios.post('/user/notifyAll', body).then(function(response) {
            if(response && response.data && response.data.message){
              _this.$noty.success(response.data.message);
            }
            _this.confirmDialog = false;
            _this.emptyValidation = false;
            _this.showSendingLoadingCircle = false;
        }).catch(function(error){
            if(error && error.response && error.response.data && error.response.data.message){
              _this.$noty.error(error.response.data.message);
            }
            console.log(error);
            _this.confirmDialog = false;
            _this.showSendingLoadingCircle = false;
        })
    },
    loadNotifications() {
      let _this = this;
      this.$axios.get('/broadcast/all').then(function(response) {
        _this.notifications = response.data.data;
      })
    }
   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>