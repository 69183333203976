<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Trips</h4>
        </div>
      </div>
      <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditTrip(editTrip, 'new')"
          >Trip hinzufügen</a
        >
        <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item active" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
                    </div>
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
          aria-controls="example1"
        />
        <br />
      </div>
      <div>
        <div>
          <div class="card">
            <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                <thead>
                  <tr>
                    <th>Bild</th>
                    <th>Name</th>
                    <th>Datum</th>
                    <th>Typ</th>
                    <th>Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="trip in trips" :key="trip._id">
                    <td class="align-middle">
                      <img
                        :src="`${$awsLargeImages}/${trip.image}`"
                        alt="img"
                        class="avatar avatar-md brround"
                      />
                    </td>
                    <td class="align-middle">{{ trip.title }}</td>
                    <td class="align-middle">{{$formatDate(trip.tripDate)}}</td>
                    <td class="align-middle">
											<div v-if="trip.category=='1 point'">1 Punkt</div>
											<div v-if="trip.category=='2 point'">2 Punkte</div>
										</td>
                    <td>
                      <a
                        title="Trip bearbeiten"
                        class="btn btn-primary btn-small mr-1"
                        @click="openEditTrip(trip, 'edit')"
                        ><i class="fas fa-edit"></i
                      ></a>
                      <a
                        title="Trip löschen"
                        class="btn btn-small btn-danger"
                        @click="openDeleteTrip(trip)"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
        </div>

        <v-dialog v-model="editDialog" width="500">
          <v-card>
            <v-card-title class="card">
              <h4 class="page-title text-primary">Trip Bearbeiten</h4>
            </v-card-title>
            <v-card-text>
              <div class="form-group">
                <label class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  v-model="editTrip.title"
                  v-bind:class="{
                    'is-invalid':
                      emptyValidation &&
                      (editTrip.title == null ||
                      editTrip.title == ''),
                  }"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Typ</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="editTrip.category"
                  v-bind:class="{
                    'is-invalid':
                      emptyValidation &&
                      (editTrip.category == null ||
                      editTrip.category == ''),
                  }"
                >
                  <option value="1 point">1 Punkt</option>
                  <option value="2 point">2 Punkte</option>
                </select>
              </div>

              <div class="form-group">
                <label class="form-label">Beschreibung</label>
                <textarea
                  rows="4"
                  class="form-control"
                  placeholder="Beschreibung"
                  v-bind:class="{
                    'is-invalid':
                      emptyValidation &&
                      (editTrip.description == null ||
                      editTrip.description == ''),
                  }"
                  v-model="editTrip.description"
                ></textarea>
              </div>
              <div class="form-group">
                <label class="form-label">Datum</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  v-model="editTrip.tripDateObject"
                  v-bind:class="{
                    'is-invalid':
                      emptyValidation &&
                      (editTrip.tripDateObject == null ||
                      editTrip.tripDateObject == ''),
                  }"

                />
              </div>
              <div class="form-group">
                <label class="form-label">Startpunkt</label>
                <GoogleMap
                  v-model="editTrip.startPointAddressObject"
                  :validated="emptyValidation && editTrip.startPointAddressObject ? true : false"
                />
                <!-- v-bind:validated="{true : emptyValidation && (editTrip.startPointAddressObject == null || editTrip.startPointAddressObject == '')}" -->
              </div>
              <div class="form-group" v-if="editTrip.category == '2 point'">
                <label class="form-label">Endpunkt</label>
                <GoogleMap
                  v-model="editTrip.endPointAddressObject"
                  :validated="emptyValidation && editTrip.endPointAddressObject ? true : false"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Maximale Teilnehmer</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  v-model="editTrip.maxParticipants"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Bild</label>
                <div class="card overflow-hidden shadow-none border" v-if="this.editTrip.editType != 'new'">
                  <img
                    :src="`${$awsLargeImages}/${editTrip.image}`"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="input-group file-browser mb-5 mt-4">
                  <input
                    type="text"
                    id="imageFileDisplay"
                    class="form-control browse-file"
                    placeholder=""
                    readonly=""
                    v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editTrip.image == null || editTrip.image == '')}"
                  />
                  <label class="input-group-text btn btn-primary">
                    Browse
                    <input
                      id="imageFile"
                      type="file"
                      class="file-browserinput"
                      style="display: none"
                      multiple=""
                      accept="image/png, image/jpeg"
                      v-on:change="setFileName()"
                    />
                  </label>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="editDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-primary btn-pill" @click="saveTrip()"
                >Speichern</a
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-card-title class="card">
              <h4 class="page-title text-primary">Trip löschen</h4>
            </v-card-title>
            <v-card-text>
              Sind sie sicher, dass sie diesen Trip löschen wollen?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="deleteDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-danger btn-pill" @click="deleteTrip(editTrip)"
                >Löschen</a
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="emptyDialog" width="300">
          <v-card>
            <br />
            <v-card-text>
              Alle Felder müssen ausgefüllt sein, um den Trip speichern zu
              können.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="emptyDialog = false"
                >Okay</a
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./widget/GoogleMap.vue";

export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      search: "",

      // Personal Data
      me: null,

      trips: [],
      editTrip: {},
      updatedPic: '',

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      emptyValidation: false,

      currentTimeSpan: 'LastMonth'
    };
  },

  name: "App",
  components: {
    GoogleMap,
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchTrip();
      } else {
        this.page = 0;
        this.searchTrip();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchTrip();
    if(this.$route.params.trip) {
      this.openEditTrip(this.$route.params.trip, 'edit');
    }
    this.$root.$on('Trips', (trip) => {
      this.openEditTrip(trip, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastTrips == this.ElementPerPage || this.lastTrips == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/trip/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
                          .then(function (response){
                            _this.lastTrips = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.trips.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/trip/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
                      _this.lastTrips = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.trips.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchTrip() {
      var _this = this;
      this.$axios
        .get("/trip/all/0/" + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
        .then(function (response) {
          _this.trips = response.data.data;
          _this.lastTrips = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchTrip() {
      var _this = this;
      this.$axios
        .get(
          "/trip/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
            + '/' + this.getCurrentTimeSpan()
        )
        .then(function (response) {
          _this.trips = response.data.data;
          _this.lastTrips = _this.trips.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openEditTrip(trip, editType) {
      this.initializeTrip();
      if (editType == "edit") {
        this.editTrip = trip;
        this.editTrip.tripDateObject = trip.tripDate.substring(
          0,
          trip.tripDate.indexOf("T")
        );

        this.editTrip.startPointAddress.location.coordinates = this.swapCoordinates(this.editTrip.startPointAddress);
        this.editTrip.endPointAddress.location.coordinates = this.swapCoordinates(this.editTrip.endPointAddress);

        const startPoint = this.editTrip.startPointAddress;
        const endPoint = this.editTrip.endPointAddress;
        this.editTrip.startPointAddressObject = startPoint;
        this.editTrip.endPointAddressObject = endPoint;
      }

      this.editDialog = true;
      this.editTrip.editType = editType;
      console.log(this.editTrip);
    },
    openDeleteTrip(trip) {
      this.editTrip = trip;
      this.deleteDialog = true;
    },

    saveTrip() {
      let _this = this;
      
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        if(document.getElementsByClassName('is-invalid').length > 0) {
          _this.emptyDialog = true;
          return;
        }
        const tripToSave = Object.assign({}, this.editTrip);
        tripToSave.tripId = tripToSave._id;
        tripToSave.tripDate = tripToSave.tripDateObject;

        if (tripToSave.editType == "new") {
          tripToSave.endPointAddress = this.copyAddress(
            tripToSave.endPointAddress,
            tripToSave.endPointAddressObject
          );
          tripToSave.startPointAddress = this.copyAddress(
            tripToSave.startPointAddress,
            tripToSave.startPointAddressObject
          );

          tripToSave.startPointAddress.location.coordinates =
            this.swapCoordinates(tripToSave.startPointAddress);
          if (tripToSave.category == "2 point") {
            tripToSave.endPointAddress.location.coordinates =
            this.swapCoordinates(tripToSave.endPointAddress);
            this.getTotalTripDistance(tripToSave.startPointAddress.place, tripToSave.endPointAddress.place, function(data){
              tripToSave.totalTripDistance = data;
              _this.saveTripToServer(tripToSave);
            });
            console.log(tripToSave.totalTripDistance);
            return; // Save already after getTotalTripDistance
          }
        } else {
          tripToSave.startPointAddress = tripToSave.startPointAddressObject;
          tripToSave.endPointAddress = tripToSave.endPointAddressObject;
        }
      });
    },
    saveTripToServer(tripToSave){
      let _this = this;
      if(document.getElementsByClassName('is-invalid').length > 0) {
        this.emptyDialog = true;
        return;
      }

      var fileToUpload = null;
      if(document.getElementById('imageFile').files.length > 0 ) {
        if(((document.getElementById('imageFile').files[0].size/1024)/1024).toFixed(4) > 20) {
          this.$showErrorFileSize(20);
          return;
        }
        fileToUpload = document.getElementById('imageFile').files[0];
        delete tripToSave.image;
      }


      delete tripToSave._id;
      delete tripToSave.startPointAddressObject;
      delete tripToSave.endPointAddressObject;
      delete tripToSave.tripDateObject;
      if (tripToSave.category == "1 point") {
        delete tripToSave.endPointAddress;
        delete tripToSave.totalTripDistance;
      }
      if (this.editTrip.editType == "new") {
        delete tripToSave.editType;
        delete tripToSave.tripId;
        const body = this.$formatUrlEncoded(tripToSave, fileToUpload);
        this.$axios
          .post("/trip/add", body)
          .then(function (response) {
            console.log(response.data);
            _this.editDialog = false;
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        delete tripToSave.editType;
        const body = this.$formatUrlEncoded(tripToSave, fileToUpload);
        this.$axios
          .put("/trip/update", body)
          .then(function (response) {
            console.log(response.data);
            _this.editDialog = false;
            this.fetchTrip();
          })
          .catch(function (error) {
            console.error(error);
          });
      }

    },
    deleteTrip(trip) {
      this.$axios
        .delete("/trip/delete/" + trip._id)
        .then(function (response) {
          console.log(response.data);
          _this.deleteDialog = false;
          this.fetchTrip();
        })
        .catch(function (error) {
          console.error(error);
        });

    },
    initializeTrip() {
      this.editTrip = {
        _id: "",
        image: "",
        title: "",
        tripDate: "",
        tripDateObject: "",
        description: "",
        startPointAddress: {
          location: {
            coordinates: [],
          },
        },
        endPointAddress: {
          location: {
            coordinates: [],
          },
        },
        startPointAddressObject: {
          location: {
            coordinates: [],
          },
        },
        endPointAddressObject: {
          location: {
            coordinates: [],
          },
        },
        maxParticipants: "",
        editType: "",
        totalTripDistance: "",
      };
      this.updatedPic = '';
    },
    checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    },
    copyAddress(address, addressObject) {
      address.location.coordinates = addressObject.coordinates;
      address.city = addressObject.city;
      address.place = addressObject.place;
      address.country = addressObject.country;
      address.state = addressObject.state;

      return address;
    },
    swapCoordinates(Address) {
      const coordinates = [];
      coordinates.push(Address.location.coordinates[1]);
      coordinates.push(Address.location.coordinates[0]);

      return coordinates;
    },
    getTotalTripDistance(startPoint, endPoint, afterFunction = null) {
      let directionsService = new google.maps.DirectionsService();
      
      const route = {
        origin: startPoint,
        destination: endPoint,
        travelMode: 'DRIVING'
      }

      // need to wait for callback, how
      directionsService.route(route, function (response, status) {
        // anonymous function to capture directions
        if (status !== "OK") {
          console.log("Directions request failed due to " + status);
          return;
        } else {
          if(afterFunction !== null) {
            afterFunction(response.routes[0].legs[0].distance.value);
          }
        }
      });
    },
    setFileName() {
       let path = document.getElementById('imageFile').value;
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
    },
    setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchTrip();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    }
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>