<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
          <v-tabs v-model="PostTab">
            <v-tab href="#tab-1">
              <div class="page-leftheader">
                <div class="headline">
                  <h4 class="page-title text-primary">Alle Beiträge</h4>
                </div>
              </div>
            </v-tab>

            <v-tab href="#tab-2">
              <div class="page-leftheader">
                <div class="headline">
                  <h4 class="page-title text-primary">Bilder</h4>
                </div>
              </div>
            </v-tab>
          </v-tabs>
      </div>
      <div class="page-rightheader" style="display: inline-flex;">
        <div v-show="showSpecificDateFilter" style="display: inline-flex">
          <input type="date" class="form-control" placeholder="" v-model="timeFilter.fromTime">&nbsp;<span style="padding-top: .5em;">bis</span>&nbsp;<input type="date" class="form-control" placeholder="" v-model="timeFilter.toTime">
        </div>&nbsp;
        <a class="btn btn-primary btn-pill" style="float: right;" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" v-bind:class="{'active': showSpecificDateFilter}" id="dropdownSpecific" @click="openFromTimeToTimePickers()">Konkreter Zeitraum</a>
                            <a class="dropdown-item" v-bind:class="{'active': currentTimeSpan == 'Today'}" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" v-bind:class="{'active': currentTimeSpan == 'LastWeek'}" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item" v-bind:class="{'active': currentTimeSpan == 'LastMonth'}" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item" v-bind:class="{'active': currentTimeSpan == 'AllTime'}" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
                    </div>
      </div>
    </div>
    <v-tabs-items v-model="PostTab">
      <!-- TABLE TAB -->
      <v-tab-item :value="'tab-1'">
        <div>
          <div>
            <input
              v-model="search"
              type="search"
              class="form-control form-control-sm"
              placeholder="Suche..."
              aria-controls="example1"
            />
            <br />
          </div>
          <div>
            <div>
              <div class="card">
                <div class="table-responsive">
                  <table class="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        <th>Bild</th>
                        <th>Benutzer</th>
                        <th>Beschreibung</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>Datum</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="post in posts" :key="post._id">
                        <td class="align-middle">
                          <img
                            :src="`${$awsLargeImages}/${post.galleryImages[0].url}`"
                            alt="img"
                            class="avatar avatar-md brround"
                          />
                        </td>
                        <td class="align-middle">{{ post.user.userName }}</td>
                        <td class="align-middle tableAbout">{{ post.description}}</td>
                        <td class="align-middle tableAbout">♥ {{ post.likes.count}}</td>
                        <td class="align-middle tableAbout">🗨 {{ post.commentsCount}}</td>
                        <td class="align-middle">{{$formatDate(post.createdAt)}}</td>
                        <td>
                          <a
                            class="btn btn-primary btn-small mr-1"
                            title="Beitrag bearbeiten"
                            @click="openEditPost(post, 'edit')"
                            ><i class="fas fa-edit"></i
                          ></a>
                          <a
                            class="btn btn-small btn-danger"
                            title="Beitrag löschen"
                            @click="openDeletePost(post)"
                            ><i class="fas fa-trash-alt"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
            </div>

            <v-dialog v-model="editDialog" width="500">
              <v-card>
                <v-card-title class="card">
                  <h4 class="page-title text-primary">Post anschauen</h4>
                </v-card-title>
                <v-card-text>
                  <div class="form-group">
                    <label class="form-label">Benutzername</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.user.userName"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Beschreibung</label>
                    <textarea
                      rows="4"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.description"
                      disabled
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Datum</label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.dateObject"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Uhrzeit</label>
                    <input
                      type="time"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.timeObject"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Addresse</label>
                    <GoogleMap
                      v-model="editPost.address.place" :disabled="true"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Bilder</label>
                    <v-carousel>
                      <v-carousel-item
                        v-for="(item,i) in editPost.galleryImages"
                        :key="i"
                        :src="`${$awsLargeImages}/${item.url}`"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <a
                    class="btn btn-primary btn-pill mr-1"
                    @click="editDialog = false"
                    >Schließen</a
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteDialog" width="500">
              <v-card>
                <v-card-title class="card">
                  <h4 class="page-title text-primary">Beitrag löschen</h4>
                </v-card-title>
                <v-card-text>
                  Sind sie sicher, dass sie diesen Beitrag löschen wollen?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <a
                    class="btn btn-primary btn-pill mr-1"
                    @click="deleteDialog = false"
                    >Abbrechen</a
                  >
                  <a class="btn btn-danger btn-pill" @click="deletePost(editPost)"
                    >Löschen</a
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-tab-item>

      <!-- IMAGE TAB -->
      <v-tab-item :value="'tab-2'">
        <div>
          <div>
            <input
              v-model="search"
              type="search"
              class="form-control form-control-sm"
              placeholder="Suche..."
              aria-controls="example1"
            />
            <br />
          </div>
          <div>
            <div>
              <div class="card">
                <div class="row">
                  <div class="col-6 pa-2" v-for="post in posts" :key="post._id">
                    <img
                      :src="`${$awsLargeImages}/${post.galleryImages[0].url}`"
                      @click="openEditPost(post, 'edit')"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
            </div>

            <v-dialog v-model="editDialog" width="500">
              <v-card>
                <v-card-title class="card">
                  <h4 class="page-title text-primary">Post anschauen</h4>
                </v-card-title>
                <v-card-text>
                  <div class="form-group">
                    <label class="form-label">Benutzername</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.user.userName"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Beschreibung</label>
                    <textarea
                      rows="4"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.description"
                      disabled
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Datum</label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.dateObject"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Uhrzeit</label>
                    <input
                      type="time"
                      class="form-control"
                      placeholder=""
                      v-model="editPost.timeObject"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Addresse</label>
                    <GoogleMap
                      v-model="editPost.address.place" :disabled="true"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">Bilder</label>
                    <v-carousel>
                      <v-carousel-item
                        v-for="(item,i) in editPost.galleryImages"
                        :key="i"
                        :src="`${$awsLargeImages}/${item.url}`"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <a
                    class="btn btn-primary btn-pill mr-1"
                    @click="editDialog = false"
                    >Schließen</a
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="deleteDialog" width="500">
              <v-card>
                <v-card-title class="card">
                  <h4 class="page-title text-primary">Beitrag löschen</h4>
                </v-card-title>
                <v-card-text>
                  Sind sie sicher, dass sie diesen Beitrag löschen wollen?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <a
                    class="btn btn-primary btn-pill mr-1"
                    @click="deleteDialog = false"
                    >Abbrechen</a
                  >
                  <a class="btn btn-danger btn-pill" @click="deletePost(editPost)"
                    >Löschen</a
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>

      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import GoogleMap from "./widget/GoogleMap.vue";

export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      search: "",
      PostTab:null,

      showSpecificDateFilter: false,
      timeFilter: {
        fromTime: null,
        toTime: null,
      },

      // Personal Data
      me: null,

      posts: [],
      editPost: {
          _id: '',
          address: {
              location: {
                  coordinates: [],
              }
          },
          user: {},
          galleryImages: [],
          createdAt: '',
          description: '',
      },

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      emptyValidation: false,
      currentTimeSpan: 'LastMonth'
    };
  },

  name: "App",
  components: {
    GoogleMap,
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchPosts();
      } else {
        this.page = 0;
        this.searchPost();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    },
    "timeFilter.fromTime": function(val){
      if(val != null){
        this.fetchPosts();
      }
    },
    "timeFilter.toTime": function(val){
      if(val != null){
        this.fetchPosts();
      }
    }
  },

  mounted() {
    this.fetchPosts();
    if(this.$route.params.post) {
      this.openEditPost(this.$route.params.post, 'edit');
    }
    this.$root.$on('Posts', (post) => {
      this.openEditPost(post, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastPosts == this.ElementPerPage || this.lastPosts == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .post('/post/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/time', {
                            time: this.getCurrentTimeSpan(),
                            fromTime: this.timeFilter.fromTime,
                            toTime: this.timeFilter.toTime
                          })
                          .then(function (response){
                            _this.lastPosts = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.posts.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/post/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
                      _this.lastPosts = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.posts.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchPosts() {
      var _this = this;
      this.$axios
        .post("/post/all/0/" + this.ElementPerPage + '/time', {
          time: this.getCurrentTimeSpan(),
          fromTime: this.timeFilter.fromTime,
          toTime: this.timeFilter.toTime
        })
        .then(function (response) {
          _this.posts = response.data.data;
          _this.lastPosts = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchPost() {
      var _this = this;
      this.$axios
        .get(
          "/post/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
            + '/' + this.getCurrentTimeSpan()
        )
        .then(function (response) {
          _this.posts = response.data.data;
          _this.lastPosts = _this.posts.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    openEditPost(post) {
        this.editPost = post;
        this.editPost.dateObject = post.createdAt.substring(
          0,
          post.createdAt.indexOf("T")
        );
        this.editPost.timeObject = post.createdAt.substring(
          post.createdAt.indexOf("T")+1, 
          post.createdAt.length-8
        );
        console.log(this.editPost)
        this.editDialog = true;
    },
    openDeletePost(post) {
        this.editPost = post;
        this.deleteDialog = true;
    },
    deletePost() {
      let _this = this;
        this.$axios
        .delete("/post/delete/" + this.editPost._id)
        .then(function (response) {
          console.log(response.data);
          _this.deleteDialog = false;
          this.fetchTrip();
        })
        .catch(function (error) {
          console.error(error);
        });

    },
    openFromTimeToTimePickers(){
      this.showSpecificDateFilter = true;
      this.currentTimeSpan = null;
    },
    setTimePicker(timeSpan) {
      this.showSpecificDateFilter = false;
      this.timeFilter = {
        fromTime: null,
        toTime: null,
      };
      this.setCurrentTimeSpan(timeSpan);
    },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchPosts();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    } 
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>