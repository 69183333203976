<template>
<div>
  <div class="page-header">
    <v-tabs v-model="reportTab">
      <v-tab href="#tab-1" @click="chooseReportType('user')">
        <div class="page-leftheader">
          <div class="headline">
            <h4 class="page-title text-primary">Benutzer</h4>
          </div>
        </div>
      </v-tab>

      <v-tab href="#tab-2" @click="chooseReportType('post')">
        <div class="page-leftheader">
          <div class="headline">
            <h4 class="page-title text-primary">Beiträge</h4>
          </div>
        </div>
      </v-tab>
      <v-tab href="#tab-2" @click="chooseReportType('trip')">
        <div class="page-leftheader">
          <div class="headline">
            <h4 class="page-title text-primary">Trips</h4>
          </div>
        </div>
      </v-tab>
    </v-tabs>
    
  </div>
  <div>
    <div>
        <input v-model="search" type="search" class="form-control form-control-sm" placeholder="Suche..." aria-controls="example1">
        <br>
    </div> 
    <div>
           
      <div>
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap" v-if="this.reportType == 'post'">
              <thead>
                <tr>
                  <th>Gemeldetes Bild</th>
                  <th>Beschreibung</th>
                  <th>Aktion</th>
                  <th>Bearbeitet</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="report in reports" :key="report._id">
                <td class="align-middle">
                    <img
                    :src="`${$awsLargeImages}/${report.galleryImages[0].url}`"
                    alt="img"
                    class="avatar avatar-md brround"
                    />
                </td>
                <td class="align-middle tableAbout">{{report.description}}</td>
                <td class="align-middle">
                    <a
                      title="Meldung ansehen"
                      class="btn btn-primary btn-small mr-1"
                      @click="openViewReport(report)"
                      ><i class="fas fa-expand"></i
                    ></a>
                    <a
                      title="Meldung löschen"
                      class="btn btn-small btn-danger"
                      @click="openDeleteReport(report)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                <td class="align-middle">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" @click="setChecked(report)"/>
                      <span class="custom-control-label"></span>
                    </label>
                </td>
              </tr>
            </tbody>
            </table>
            <table class="table card-table table-vcenter text-nowrap" v-if="this.reportType == 'user'">
              <thead>
                <tr>
                  <th>Profilbild</th>
                  <th>Benutzername</th>
                  <th>Email</th>
                  <th>Beschreibung</th>
                  <th>Aktion</th>
                  <th>Bearbeitet</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="report in reports" :key="report._id">
                <td class="align-middle">
                    <img
                    :src="`${$awsLargeImages}/${report.image}`"
                    alt="img"
                    class="avatar avatar-md brround"
                />
                </td>
                <td class="align-middle">{{report.userName}}</td>
                <td class="align-middle">{{report.email}}</td>
                <td class="align-middle tableAbout">{{report.about}}</td>
                <td>
                    <a
                      class="btn btn-primary btn-small mr-1"
                      @click="openViewReport(report)"
                      ><i class="fas fa-expand"></i
                    ></a>
                    <a
                      class="btn btn-small btn-danger"
                      @click="openDeleteReport(report)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                  <td class="align-middle">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" @click="setChecked(report)"/>
                      <span class="custom-control-label"></span>
                    </label>
                </td>
              </tr>
            </tbody>
            </table>
            <table class="table card-table table-vcenter text-nowrap" v-if="this.reportType == 'trip'">
              <thead>
                <tr>
                  <th>Bild</th>
                  <th>Name</th>
                  <th>Beschreibung</th>
                  <th>Kategorie</th>
                  <th>Aktion</th>
                  <th>Bearbeitet</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="report in reports" :key="report._id">
                <td class="align-middle">
                    <img
                      :src="`${$awsLargeImages}/${report.image}`"
                      alt="img"
                      class="avatar avatar-md brround"
                    />
                </td>
                <td class="align-middle">{{report.title}}</td>
                <td class="align-middle tableAbout">{{report.description}}</td>
                <td class="align-middle">{{report.category}}</td>
                <td>
                    <a
                      class="btn btn-primary btn-small mr-1"
                      @click="openViewReport(report)"
                      ><i class="fas fa-expand"></i
                    ></a>
                    <a
                      class="btn btn-small btn-danger"
                      @click="openDeleteReport(report)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                  <td class="align-middle">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" @click="setChecked(report)" />
                      <span class="custom-control-label"></span>
                    </label>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
          
        </div>
        <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      </div>

      <v-dialog 
        v-model="viewDialog"
        width="500"
        >
        <v-card>
          <v-card-title class="card">
              <h4 class="page-title text-primary">Meldung ansehen</h4>
            </v-card-title>
            <v-card-text>
                <div v-if="this.reportType == 'user'" class="form-group">
                  <label class="form-label">Benutzername</label>
                  <input class="form-control" type="text" v-model="viewReport.userName" disababled>
                </div>
                <div v-if="this.reportType == 'trip'" class="form-group">
                  <label class="form-label">Name</label>
                  <input class="form-control" type="text" v-model="viewReport.title" disababled>
                </div>
                <div class="form-group">
                  <div v-if="this.reportType != 'post'">
                    <label class="form-label">Bild</label>
                    <img
                        :src="`${$awsLargeImages}/${viewReport.image}`"
                        alt="img"
                        class="avatar avatar-xxl brround"
                    />
                  </div>
                    <b-carousel
                      :interval="0"
                      controls
                      indicators
                      no-animation
                      v-else
                    >
                        <b-carousel-slide
                          v-for="img in viewReport.galleryImages" :key="img.url"
                          :img-src="`${$awsLargeImages}/${img.url}`"
                        ></b-carousel-slide>
                    </b-carousel>
                </div>
                <div class="form-group">
                  <label class="form-label">Beschreibung</label>
                  <input class="form-control" type="text" v-model="viewReport.about" disababled v-if="this.reportType == 'user'">
                  <input class="form-control" type="text" v-model="viewReport.description" disababled v-if="this.reportType != 'user'">
                </div>
                <div class="form-group">
                  <label class="form-label">Gemeldet von</label>
                  <input v-for="reportingUser in viewReport.data" :key="reportingUser._id" class="form-control mb-1" type="text" v-model="reportingUser.userName" disababled>
                </div>
                <!-- <div v-if="viewReport.reason != null || viewReport.reason != ''">
                  <label class="form-label">Begründung</label>
                  <div class="card p-2" >
                    {{viewReport.reason}}
                  </div>
                </div> -->
            </v-card-text>
            <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="viewDialog=false">Schließen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog 
        v-model="deleteDialog"
        width="500">\
        <v-card>
          <v-card-title class="card">
              <h4 class="page-title text-primary">Meldung löschen</h4>
            </v-card-title>
            <v-card-text>
              Sind sie sicher, dass sie diese Meldung löschen wollen?
            </v-card-text>
            <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="takeAction(viewReport)">Löschen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      </div>
    </div>
  
</div>
</template>

<script>
export default {

  components: {
  },

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,
      search: "",
      convertedTenants: [],
      tenantFilter: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      tenants: [
        {
          name: '',

        }
      ],
      roles: [],
      menu: false,
      createmode: true,

      // Personal Data
      me: null,

    reports: [
    ],
    reportType: '',
    viewReport: {
        _id: "",
        username: "",
        email: "",
        image: "",
        about: "",
        data : [
            {}
        ],
        galleryImages: [{}],
    },

    viewDialog: false,
    deleteDialog: false,
    reportTab: null,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchReported();
      } else {
        this.page = 0;
        this.searchReport();
      }
    },
  },

  mounted() {
    this.chooseReportType('user');
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastReports == this.ElementPerPage || this.lastReports == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    const body = {
                      type: this.reportType,
                      skip: this.page * this.ElementPerPage,
                      limit: this.ElementPerPage,
                    }
                    this.$axios
                          .get('/report/detailWithLimit', body)
                          .then(function (response){
                            _this.lastReports = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.reports.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/report/search/'+ this.reportType + '/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage).then(function(response){
                      _this.lastReports = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.reports.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchReported() {
      var _this = this;
      const body = {
        type: this.reportType,
        skip: 0,
        limit: this.ElementPerPage,
      }
      this.$axios.post('/report/detailWithLimit', body).then(function(response){
        _this.reports = response.data.data;
        _this.lastReports = response.data.data.length;
        console.log(response.data.data);
      }).catch(function(error){
        console.error(error);
      });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openViewReport(report) {
      this.viewReport = report;
      this.viewDialog = true;
      console.log(this.viewReport);
    },
    openDeleteReport(report) {
      this.viewReport = report;
      this.deleteDialog = true;


    },
    chooseReportType(type) {
        this.reportType = type;
        let typeString;

        if(type == 'user') {
            typeString = 'Benutzer';
        } else if(type == 'post') {
            typeString = 'Beitrag';
        } else if(type == 'trip') {
            typeString = 'Trip';
        }

        this.fetchReported();
        console.log(this.reports);
    },
    searchReport() {
      var _this = this;
      this.$axios
        .get(
          "/report/search/" +
            this.reportType + "/" + 
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
        )
        .then(function (response) {
          _this.reports = response.data.data;
          _this.lastReports = _this.reports.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    takeAction(report) {
      let _this = this;
      const reportToTakeAction = {
        id: report._id,
        type: this.reportType,
      }
      this.$axios.post('/report/takeAction', reportToTakeAction).then(function (response) {
        _this.fetchReported();
      }).catch(function(error){
        console.log(error);
      })
    },
    setChecked(report) {
      let _this = this;
      const reportToCheck = {
        id: report._id
      }
      this.$axios.put('/report/checked', reportToCheck).then(function (response) {
        _this.fetchReported();
        console.log(response);
      })
      .catch(function(error){
        console.error(error);
      })
    }

  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

