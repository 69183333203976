<template>
<div>
  <div class="page-header">
    <div class="page-leftheader">
      <!-- <div class="headline"><h4 class="page-title text-primary">Benutzer</h4 ></div> -->
      <v-tabs v-model="userTab">
            <v-tab href="#tab-1">
              <div class="page-leftheader">
                <div class="headline">
                  <h4 class="page-title text-primary">Benutzer</h4>
                </div>
              </div>
            </v-tab>

            <v-tab href="#tab-2" @click="handleTabChange('#tab-2')">
              <div class="page-leftheader">
                <div class="headline">
                  <h4 class="page-title text-primary">Map</h4>
                </div>
              </div>
            </v-tab>
          </v-tabs>

    </div>
    <div class="page-rightheader">
      <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('blacklisted')"
          >Gesperrte Nutzer</a
        >
      <a class="btn btn-primary btn-pill d-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item active" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
                    </div>
    </div>
    
  </div>
  <div>
    <v-tabs-items v-model="userTab">
    <v-tab-item :value="'tab-1'">
      <div>
        <input v-model="search" type="search" class="form-control form-control-sm" placeholder="Suche..." aria-controls="example1">
        <br>
    </div> 
    <div>
      <div>
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Profilbild</th>
                  <th @click="sort = 'userName', order = !order,order ? orderReq = '1' : orderReq = '-1',search == '' ? fetchUser() : searchUser()">Benutzername<i v-if="sort == 'userName' && order" class="fe fe-chevron-down"></i><i v-if="sort == 'userName' && !order" class="fe fe-chevron-up"></i></th>
                  <th @click="sort = 'email', order = !order,order ? orderReq = '1' : orderReq = '-1', search == '' ? fetchUser() : searchUser()">Email<i v-if="sort == 'email' && order" class="fe fe-chevron-down"></i><i v-if="sort == 'email' && !order" class="fe fe-chevron-up"></i></th>
                  <th>Telefonnummer</th>
                  <th @click="sort = 'createdAt', order = !order,order ? orderReq = '1' : orderReq = '-1', search == '' ? fetchUser() : searchUser()">Anmeldedatum<i v-if="sort == 'createdAt' && order" class="fe fe-chevron-down"></i><i v-if="sort == 'createdAt' && !order" class="fe fe-chevron-up"></i></th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="user in users" :key="user._id">
                <td class="align-middle">
                  <img :src="`${$awsSmallImages}/${user.image}`" alt="img" class="avatar avatar-md brround">
                </td>
                <td class="align-middle">{{user.userName}}</td>
                <td class="align-middle">{{user.email}}</td>
                <td class="align-middle">{{user.telephoneNumber}}</td>
                <td class="align-middle">{{convertDateToString(user.createdAt)}}</td>
                <td>
                  <a title="Nutzer bearbeiten" class="btn btn-primary btn-small mr-1" @click="openEditUser(user)"><i class="fas fa-edit"></i></a>
                  <a title="Nutzerfahrzeuge bearbeiten" class="btn btn-primary btn-small mr-1" @click="fetchUserCars(user)"><i class="fas fa-car"></i></a>
                  <a title="Nutzer löschen" class="btn btn-small btn-danger mr-1" @click="openDeleteUser(user)"><i class="fas fa-trash-alt"></i></a>
                  <a title="Nutzer sperren" class="btn btn-small btn-danger" @click="openBanUser(user)"><i class="fas fa-ban"></i></a>
                </td>
                
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      </div>
      </div>
    </v-tab-item>
    <v-tab-item :value="'tab-2'">
      <div>
        <GmapMap
        :center="center"
        :zoom="4"
        style="width: 100%; height: 800px"
      >
      <GmapCluster
      >
        <GmapMarker
          :key="m._id"
          v-for="(m) in usersByCountry"
          :position="{lat: m.location.coordinates[1], lng: m.location.coordinates[0]}"
          :clickable="true"
          :draggable="false"
          @click="center={lat: m.location.coordinates[1], lng: m.location.coordinates[0]}"
        />
        </GmapCluster>
      </GmapMap>
        <!-- <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                      <th>Land</th>
                      <th>Anzahl der Nutzer</th>
                  </thead>
                  <tbody>
                    <tr v-for="country in usersByCountry" :key="country._id">
                    <td class="align-middle">{{ country._id }}</td>
                    <td class="align-middle">{{ country.count }}</td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div> -->
      </div>
    </v-tab-item>
  </v-tabs-items>

    

      <!-- Dialog Nutzer bearbeiten -->
      <v-dialog
        v-model="editDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card">
            <h4 class="page-title text-primary">Benutzer Bearbeiten</h4>
          </v-card-title>
          <v-card-text>

          <div class="form-group"> 
            <label class="form-label">Benutzername</label> 
            <input type="text" class="form-control" placeholder="" v-model="editUser.userName" v-bind:class="{'is-invalid': emptyValidation && (editUser.userName == null || editUser.userName == '')}"> 
          </div>

          <div class="form-group"> 
            <label class="form-label">Email</label> 
            <input type="email" class="form-control" placeholder="" v-model="editUser.email" v-bind:class="{'is-invalid': emptyValidation && (editUser.email == null || editUser.email == '')}">
          </div>

          <div class="form-group"> 
            <label class="form-label">Geburtstag</label> 
            <input type="date" class="form-control" placeholder="" v-model="editUser.dateOfBirthObject" v-bind:class="{'is-invalid': emptyValidation && (editUser.dateOfBirthObject == null || editUser.dateOfBirthObject == '')}"> 
          </div>

          <div class="form-group"> 
            <label class="form-label">Telefonnummer</label> 
            <input type="text" class="form-control" placeholder="" v-model="editUser.telephoneNumber"> 
          </div>
          
          <div class="form-group"> 
            <label class="form-label">About Me</label> 
            <textarea rows="5" class="form-control" placeholder="" v-model="editUser.about" v-bind:class="{'is-invalid': emptyValidation && (editUser.about == null || editUser.about == '')}"></textarea> 
          </div> 
          
          <div class="form-group"> 
            <label class="form-label">Sprache</label>
            <select name="" id="" class="form-control" v-model="editUser.language" v-bind:class="{'is-invalid': emptyValidation && (editUser.language == null || editUser.language == '')}">
              <option value="de">Deutsch</option>
              <option value="en">Englisch</option>
            </select>
          </div>
          
          <div class="form-group"> 
            <label class="form-label">Verfügbare Coins</label>
            <input type="number" class="form-control" placeholder="" v-model="editUser.currentCoins">
          </div>

          <div class="form-group">
            <label class="form-label">Gefahrene Kilometer</label>
            <input type="Number" class="form-control" placeholder="" v-model="editUser.drivenKilometers">
          </div>

          <div class="form-group"> 
            <label class="form-label">Verifikationsstatus</label>
            <select name="" id="" class="form-control" v-model="editUser.isVerified">
              <option value="true">Verifiziert</option>
              <option value="false">Nicht verifiziert</option>
            </select>
            <a v-if="!editUser.isVerified" class="btn btn-primary btn-pill mt-2" @click="sendNewVerifyMail(editUser)">Neue Email zum Verifizieren schicken</a>
          </div>

          <div class="form-group"> 
            <label class="form-label">Status</label>
            <select name="" id="" class="form-control" v-model="editUser.status">
              <option value="1">Aktiv</option>
              <option value="0">Gesperrt</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label">Betriebssystem</label>
            <input type="text" class="form-control" placeholder="" v-model="editUser.system" disabled>
          </div>
          <div class="form-group">
            <label class="form-label">App-Version</label>
            <input type="text" class="form-control" placeholder="" v-model="editUser.version" disabled>
          </div>
          <div class="form-group">
            <label class="form-label">Modell</label>
            <input type="text" class="form-control" placeholder="" v-model="editUser.model" disabled> 
          </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="saveUser(editUser)">Speichern</a>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- Nutzerfahrzug löschen -->
      <v-dialog v-model="DeleteCarconfirmDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Bestätigen</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Möchten sie dieses Fahrzeug  wirklich löschen?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="deleteCar()">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>


      <!-- Fahrzeuge bearbeiten Dialog -->
      <v-dialog
        v-model="editCarDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card ">
            Fahrzeug bearbeiten
          </v-card-title>

          <v-card-text>
            <div class="form-group">
                    <label class="form-label">Bilder</label>
                    <v-carousel>
                      <v-carousel-item
                        v-for="(item,i) in editCar.galleryImages"
                        :key="i"
                        :src="`${$awsLargeImages}/${item.url}`"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
            <div class="form-group">
                <label class="form-label">Modell</label>
                <input type="text" class="form-control" placeholder="Modell..." v-model="editCar.model" v-bind:class="{'is-invalid': emptyValidation && (editCar.model == null || editCar.model == '')}">
            </div>
            <div class="form-group">
                <label class="form-label">Marke</label>
                <input type="text" class="form-control" placeholder="Marke..." v-model="editCar.brand" v-bind:class="{'is-invalid': emptyValidation && (editCar.make == null || editCar.make == '')}">
                <div class="card mb-0" id="autoComplete">
                    <!-- <ul class="list-group p-0">
                      <li class="list-group-item autoCompleteList" v-for="el in autoCompleteResult" :key="el" @click="chooseAutoComplete(el)">{{el}}</li>
                    </ul> -->
                </div>
            </div>
            <div class="form-group"> 
              <label class="form-label">Beschreibung</label> 
              <textarea rows="5" class="form-control" placeholder="" v-model="editCar.description" v-bind:class="{'is-invalid': emptyValidation && (editUser.about == null || editUser.about == '')}"></textarea> 
            </div>          
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="editCarDialog=false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="saveCar()">Speichern</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Fahrzeuge anzeigen -->
      <v-dialog
        v-model="showCarsDialog"
        width="75%"
      >
        <v-card>
          <v-card-title class="card">
            <h4 class="page-title text-primary">Fahrzeuge von {{editUser.userName}}</h4>
          </v-card-title>
          <v-card-text>
            <div class="card">
              <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                    <tr>
                      <th>Bild</th>
                      <th>Marke</th>
                      <th>Model</th>
                      <!-- <th>Description</th> -->
                      <th>Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="car in editUser.cars" :key="car._id">
                      <td class="align-middle">
                        <img :src="`${$awsSmallImages}/${car.galleryImages[0].url}`" alt="img" class="avatar avatar-md brround"/>
                      </td>
                      <td class="align-middle">{{car.brand}}</td>
                      <td class="align-middle">{{car.model}}</td>
                      <!-- <td class="align-middle">{{car.description}}</td> -->
                      <td class="align-middle">
                        <a title="Fahrzeug bearbeiten" class="btn btn-primary btn-small mr-1" @click="openEditCar(car)"><i class="fas fa-edit"></i></a>
                        <a title="Fahrzeug löschen" class="btn btn-small btn-danger mr-1" @click="openDeleteCar(car)" ><i class="fas fa-trash-alt"></i></a>
                      </td>
    
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="showCarsDialog=false">Schließen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      

      <!-- Dialog Nutzer löschen -->
      <v-dialog
        v-model="deleteDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card ">
            Benutzer Löschen
          </v-card-title>

          <v-card-text>
            <label class="form-label">Bitte geben sie den Grund für das Löschen des Benutzers an</label>
            <textarea rows="5" class="form-control" placeholder="" v-model="deleteReason"></textarea>           
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteUser(editUser)">Löschen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="banDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Benutzer blockieren</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Sind sie sicher, dass sie diesen Benutzer auf die Blacklist setzen möchten? <br><br>
          Ein gesperrter Nutzer wird gelöscht und kann nicht wiederhergestellt werden.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-danger btn-pill" @click="banUser()">Blockieren</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  
</div>
</template>

<script>
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {

  components: {
    GmapCluster
  },

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      lastUsers: null,
      sort: null,
      order: true,
      orderReq: 1,
      routes: {
      },
      search: "",
      users: [],
      roles: [],

      menu: false,
      createmode: true,

      // Personal Data
      me: null,
      editCar: {},

      editUser: {
        userName: "",
        email: "",
        dateOfBirth: "",
        image: "",
        about: "",
        telephoneNumber: "",
        language: "",
        currentCoins: "",
        drivenKilometers: "",
        status: "",
        dateOfBirthObject: "",
        cars: null,
      },

      editCar: {
        brand: null,
        model: null,
      },

      editDialog: false,
      showCarsDialog: false,
      editCarDialog:false,
      deleteDialog: false,
      emptyDialog: false,
      banDialog: false,
      DeleteCarconfirmDialog: false,

      emptyValidation: false,
      deleteReason: '',
      currentTimeSpan: 'AllTime', 
      userTab: null,
      center: {lat: 51.1642292, lng: 10.4541194}, 
      usersByCountry: [],
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search : function(){
      if(this.search == ""){
        this.fetchUser();
      }else{
        console.log(this.search);
        this.page = 0;
        this.searchUser()
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    },
    // 'order': function(){
    //   console.log("orderchanged")
    //   if(!this.order){
    //     this.orderReq = '1';
    //     console.log("false")
    //   }else if(this.order){
    //     this.orderReq = '-1';
    //     console.log("true")
    //   }
    // }
  },

  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '../../public/js/custom.js')
    document.head.appendChild(recaptchaScript)
    this.fetchUser();
    this.fetchUserPerCountry();
    if(this.$route.params.user) {
      this.openEditUser(this.$route.params.user, 'edit');
    }
    this.$root.$on('Users', (user) => {
      this.openEditUser(user, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastUsers == this.ElementPerPage || this.lastUsers == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    if(this.sort == null){
                      this.page++;
                      this.$axios
                            .get('/user/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
                            .then(function (response){
                              _this.lastUsers = response.data.data.length;
                              if(response.data.data.length >= 1) {
                                response.data.data.forEach(item => _this.users.push(item));
                              }
                            })
                            .catch(this.onError);
                    }else{
                      this.page++;
                      this.$axios
                            .get('/user/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan() + '/' + this.sort + '/' + this.orderReq)
                            .then(function (response){
                              _this.lastUsers = response.data.data.length;
                              if(response.data.data.length >= 1) {
                                response.data.data.forEach(item => _this.users.push(item));
                              }
                            })
                            .catch(this.onError);
                    }
                  }
                  else{
                    if(this.sort == null){
                      console.log('infinitesearch');
                      this.page++;
                      this.$axios.get('/user/admin/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage  + '/' + this.getCurrentTimeSpan()).then(function(response){
                        _this.lastUsers = response.data.data.length;
                        if(response.data.data.length >= 1) {
                          response.data.data.forEach(item => _this.users.push(item));
                        }
                      }).catch(function(error){
                        console.error(error);
                      });
                    }else{
                      console.log('infinitesearch');
                      this.page++;
                      this.$axios.get('/user/admin/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage  + '/' + this.getCurrentTimeSpan() + '/' + this.sort + '/' + this.orderReq).then(function(response){
                        _this.lastUsers = response.data.data.length;
                        if(response.data.data.length >= 1) {
                          response.data.data.forEach(item => _this.users.push(item));
                        }
                      }).catch(function(error){
                        console.error(error);
                      });
                    }
                  }
                }
            }, 500);
        }
    },
    fetchUser() {
      if(this.search != null || this.search != '')
      var _this = this;
      this.$axios.get('/user/all/0/'+ this.ElementPerPage  + '/' + this.getCurrentTimeSpan() + '/' + this.sort + '/' + this.orderReq).then(function(response){
        _this.users = response.data.data;
        _this.lastUsers = response.data.data.length;
      }).catch(function(error){
        console.error(error);
      });
    },
    fetchUserCars(user){
      var _this = this;
      this.editUser = user;
      this.$axios.get('auto/user/' + user._id).then(function(response){
        _this.editUser.cars = response.data.data
        _this.showCarsDialog = true;
        _this.$forceUpdate();
      })
    },
    searchUser(){
      var _this = this;
      if(this.sort == '' || this.sort == null){
        
        this.$axios.get('/user/search/' + this.search +'/'+ this.page + '/' + this.ElementPerPage  + '/' + this.getCurrentTimeSpan()).then(function(response){
          _this.users = response.data.data;
          _this.lastUsers = _this.users.length;
        }).catch(function(error){
          console.error(error);
        });
      }else{
        this.$axios.get('/user/search/' + this.search +'/'+ this.page + '/' + this.ElementPerPage  + '/' + this.getCurrentTimeSpan() + '/' + this.sort + '/' + this.orderReq).then(function(response){
          _this.users = response.data.data;
          _this.lastUsers = _this.users.length;
        }).catch(function(error){
          console.error(error);
        });
      }
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openEditCar(car){
      var _this = this;

      this.editCar = Object.assign({},car);
      this.editCarDialog = true;
    },
    openDeleteCar(car){
      var _this = this;
      this.editCar = car;
      this.DeleteCarconfirmDialog = true;
      
    },
    deleteCar(){
      var _this = this;
      this.$axios.delete("/auto/delete/" + _this.editCar._id).then(function (response) {
          _this.DeleteCarconfirmDialog = false;
          _this.fetchUserCars(_this.editUser);
          _this.$forceUpdate();

        }).catch(function(error){
          console.error(error);
        });
    },
    saveCar(){
      var _this = this;

      this.editCar.existingImages = JSON.stringify(this.editCar.galleryImages);
      this.editCar.deletedImages = JSON.stringify([]);
      this.editCar.autoId = this.editCar._id; 

      const carToSave = Object.assign({}, this.editCar);

      delete carToSave._id 
      delete carToSave.status
      delete carToSave.user
      delete carToSave.createdAt
      delete carToSave.updatedAt
      delete carToSave.__v;

      const body = this.$formatUrlEncoded(carToSave)


      this.$axios.put('/auto/update', body).then(function(response){
        _this.editCarDialog = false;
        // _this.emptyValidation = false;
        _this.$forceUpdate();
      }).catch(function(error){
        console.error(error);
      });
    },
    openEditUser(user){
      var _this = this;

      this.$axios.get(`/user/admin/profile/${user._id}/0/1`).then(function(response){
        _this.editUser = Object.assign({}, response.data.data);
        if(_this.editUser.dateOfBirth) {
          _this.editUser.dateOfBirthObject = _this.editUser.dateOfBirth.year + '-' + _this.editUser.dateOfBirth.month + '-' + _this.editUser.dateOfBirth.date;
        }
        _this.editDialog = true;
        delete _this.editUser.coinvalue;
        delete _this.editUser.createdAt;
        delete _this.editUser.currentLevel;
        delete _this.editUser.currentLevelImage;
        delete _this.editUser.fcmToken;
        delete _this.editUser.followers;
        delete _this.editUser.following;
        delete _this.editUser.forgotPasswordOtp;
        delete _this.editUser.homezone;
        delete _this.editUser.inHomeZone;
        delete _this.editUser.isHomeZoneAdded;
        delete _this.editUser.isNotificationEnabled;
        delete _this.editUser.location;
        delete _this.editUser.loginType;
        delete _this.editUser.onlineStatus;
        delete _this.editUser.password;
        delete _this.editUser.totalCoinsEarned;
        delete _this.editUser.trackStatus;
        delete _this.editUser.updatedAt;
        delete _this.editUser.coinValue;
        delete _this.editUser.lastLocationOutsideHomeZone;
        delete _this.editUser.lastActive;
      }).catch(function(error){
        console.error(error);
      })
      
    },
    saveUser() {
      let _this = this;
      
      this.emptyValidation = true;
      this.$forceUpdate();

      if(this.editUser.telephoneNumber) {
        this.editUser.telephoneNumber = this.editUser.telephoneNumber.trim().replace(/[()+]/g, '');
        if(!/^[0-9]*$/.test(this.editUser.telephoneNumber)) {
          this.$showErrorInvalid('Telefonnummer');
          return;
      }
      }
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.editUser.email)) {
        this.$showErrorInvalid('Email');
        return;
      }
      
      Vue.nextTick(() => {
        _this.saveUserToServer();
      });
    },
    saveUserToServer(){
      let _this = this;
      
      const userSaveSchema = { 
        userName: '',
        _id: '',
        dateOfBirth: {},
        image: '',
        about: '',
        telephoneNumber: '',
        language: '',
        currentCoins: '',
        status: '',
        drivenKilometers: '',
        email: '',
        isVerified: '',
      }
      if(document.getElementsByClassName('is-invalid').length > 0) {
        this.emptyDialog = true;
        return;
      }
      const userToSave = Object.assign({}, this.editUser);
      userToSave.dateOfBirth = this.convertDateToObject(userToSave.dateOfBirthObject);

      delete userToSave.dateOfBirthObject;
      delete userToSave.__v;
      delete userToSave.deviceId;
      delete userToSave.version;
      delete userToSave.model;
      delete userToSave.system;
      delete userToSave.cars;
      if(userToSave.telephoneNumber === null) delete userToSave.telephoneNumber;

      for(const [key] of Object.entries(userToSave)) {
        const validKeys = Object.keys(userSaveSchema);
        if(!validKeys.includes(`${key}`)) {
          delete userToSave[key];
        }
      }
      
      const body = this.$formatUrlEncoded(userToSave);
      this.$axios.put('/user/update', body).then(function(response){
        _this.editDialog = false;
        _this.emptyValidation = false;
        _this.$forceUpdate();
      }).catch(function(error){
        console.error(error);
      });
    },
    openDeleteUser(user) {
      this.deleteDialog = true;
      this.editUser = user;
    },
    deleteUser() {
      let _this = this;
      // Delete User
      const body = {
        userId: this.editUser._id,
        reason: this.deleteReason,
      }
      this.$axios.post('user/delete', body).then(function(response){
        console.log(response.data);
        _this.deleteDialog = false;
      }).catch(function(error){
        console.error(error);
      })
    },
    convertDateToString(dateString){
      var dateobj = this.convertDateToObject(dateString);
      let str = dateobj.date.substring(0,2) + "." + dateobj.month + "." + dateobj.year;
      return str;
    },
    convertDateToObject(dateString) {
      let dateObject = {
        year: '',
        month: '',
        date: '',
      }
      dateObject.year = dateString.substring(0, dateString.indexOf('-'));
      dateObject.month = dateString.substring(dateObject.year.length + 1, dateObject.year.length + 3);
      dateObject.date = dateString.substring(dateObject.month.length + dateObject.year.length + 2);

      return dateObject;
    },
    openBanUser(user) {
      this.editUser = user;
      this.banDialog = true;
    },
    banUser() {
      let _this = this;

      const body = { userId: this.editUser._id };
      this.$axios.post('/user/blacklist/', body).then(function(response){
        _this.fetchUser();
        _this.banDialog = false;
      }).catch(function(error){
        console.error(error);
      })
    },
    setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      if(this.userTab == 'tab-1') {
        this.fetchUser();
      } else {
        this.fetchUserPerCountry();
      }

    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    },
    fetchUserPerCountry() {
      this.$axios.get('/user/country/' + this.getCurrentTimeSpan()).then((response) => {
        this.usersByCountry = response.data.data;
      })
    },
    handleTabChange(tab) {
      if(tab == '#tab-2') {
        this.setTimePicker('AllTime');
        this.fetchUserPerCountry();
      }
    },
    sendNewVerifyMail(editUser) {
      console.log(editUser);
      const body = { 
        _id: editUser._id,
        email: editUser.email
      }
      this.$axios.post('/sendValidationEmail', body, { headers: { language: editUser.language }}).then(() => {
        this.$noty.success("Email versendet");
      }).catch((error) => {
        this.$showErrorWithMessage(error);
      });
    }
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

