export function getAxiosParams(){
    return {
        // baseURL: "http://localhost:80/api/V1/",
        // baseURL: "https://ec2-3-68-96-33.eu-central-1.compute.amazonaws.com/api/V1/",
        baseURL: "https://enjoy-the-street.de/api/V1/",
        headers: {
          "x-api-key": "PPdH1WKwRa2BFGG57g16yxyqqevJ2kfJZgeQ2OcIO85iyFbvLZm7mIoAMLaCRdbGkdkaRkIucGAAbAULq6IACUZfrZF82HCaIKbqUJIWf2nfx5OLZk9r5YukL0hEvJC2",
          // "x-api-key" : "HDJS7365JSJDBNCLKSDNVHDSB4TH",
          "Content-Type": "application/json",
        },
      };
}

export function getAWSImageUrls(){
  return {
    smallImages: "https://dppb36wsqd3di.cloudfront.net/street",
    mediumImages: "https://dppb36wsqd3di.cloudfront.net/street",
    largeImages: "https://dppb36wsqd3di.cloudfront.net/street",
  }
  // PRODUCTION
  // return {
  //   smallImages: "https://d27mku895lz0eb.cloudfront.net/image/320/street/",
  //   mediumImages: "https://d27mku895lz0eb.cloudfront.net/image/720/street/",
  //   largeImages: "https://d27mku895lz0eb.cloudfront.net/image/1080/street/",
  // }
}