<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Modelle</h4>
        </div>
      </div>
      <div class="page-rightheader">
          <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditModel(editModel, 'new')"
          >Modell hinzufügen</a
        >
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="syncWithExt()"
          >Mit Datenbank synchronisieren</a
        >
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('manufacturers')"
          >Automarken</a
        >
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
          aria-controls="example1"
        />
        <br />
      </div>
      <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                      <th>Kategorie</th>
                      <th>Marke</th>
                      <th>Modell</th>
                      <th>Aktion</th>
                  </thead>
                  <tbody>
                    <tr v-for="model in models" :key="model._id">
                    <td class="align-middle">{{ model.category }}</td>
                    <td class="align-middle">{{ model.make }}</td>
                    <td class="align-middle">{{ model.model }}</td>
                    <td>
                      <a
                      title="Marke bearbeiten"
                      class="btn btn-primary btn-small mr-1"
                      @click="openEditModel(model, 'edit')"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a
                      title="Marke löschen"
                      class="btn btn-small btn-danger"
                      @click="openDeleteModel(model)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                    </td>
                      </tr>
                  </tbody>
              </table>
          </div>

      </div>
      <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
    </div>

    <!-- V-Dialoge -->
    <v-dialog 
        v-model="editDialog"
        width="500">
        <v-card>
         <v-card-title class="card">
            <h4 class="page-title text-primary" v-if="editModel.editType == 'edit'">Modell bearbeiten</h4>
            <h4 class="page-title text-primary" v-if="editModel.editType == 'new'">Modell hinzufügen</h4>
          </v-card-title>
          <v-card-text>
            <div class="form-group"> 
            <label class="form-label">Kategorie</label>
            <select name="" id="" class="form-control" v-model="editModel.category">
              <option value="Car">Auto</option>
              <option value="Motorbike">Motorrad</option>
            </select>
          </div>
            <div class="form-group">
                <label class="form-label">Modell</label>
                <input type="text" class="form-control" placeholder="Modell..." v-model="editModel.model" v-bind:class="{'is-invalid': emptyValidation && (editModel.model == null || editModel.model == '')}">
            </div>
            <div class="form-group">
                <label class="form-label">Marke</label>
                <input type="text" class="form-control" placeholder="Marke..." v-model="editModel.make" v-bind:class="{'is-invalid': emptyValidation && (editModel.make == null || editModel.make == '')}">
                <div class="card mb-0" id="autoComplete">
                    <ul class="list-group p-0">
                      <li class="list-group-item autoCompleteList" v-for="el in autoCompleteResult" :key="el" @click="chooseAutoComplete(el)">{{el}}</li>
                    </ul>
                </div>
            </div>
          </v-card-text>
            <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="saveModel()">Speichern</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog 
        v-model="deleteDialog"
        width="500">
        <v-card>
         <v-card-title class="card">
            <h4 class="page-title text-primary">Modell löschen</h4>
          </v-card-title>
          <v-card-text>
            Sind sie sicher, dass sie dieses Modell löschen wollen?
          </v-card-text>
                    <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteModel()">Löschen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      models: [],
      autoCompleteResult: [],
      editModel: '',


      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      emptyValidation: false,
      autoCompleteSilent: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchModels();
      } else {
        this.page = 0;
        this.searchModels();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    },
    editModel: {
      handler() {
        if(!this.autoCompleteSilent && this.editModel.make.length > 0) {
          this.autoComplete();
        } else {
          this.hideAutoComplete();
        }
      },
      deep: true
    },
  },

  mounted() {
    this.fetchModels();
    
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastCars == this.ElementPerPage || this.lastCars == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/make/model/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage)
                          .then(function (response){
                            _this.lastCars = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.models.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    this.page++;
                    this.$axios.get('make/model/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage).then(function(response){
                      _this.lastCars = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.models.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchModels() {
      var _this = this;
      this.$axios
        .get("/make/model/all/0/" + this.ElementPerPage)
        .then(function (response) {
          _this.models = response.data.data;
          _this.lastModels = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    openEditModel(model, type) {
        this.autoCompleteSilent = true;
        this.initializeModel();
        if(type == 'edit') {
            this.editModel = model;
        }
        this.editModel.editType = type;
        if(model.category == null || model.category == "") {
          this.editModel.category = 'Car';
        }
        this.editDialog = true;
        Vue.nextTick(() => {
          this.autoCompleteSilent = false;
        });
    },
    saveModel() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        if(document.getElementsByClassName('is-invalid').length > 0) {
          this.emptyDialog = true;
          return;
        }
        _this.saveModelToServer();
      });
    },
    saveModelToServer() {
      let _this = this;
      const modelToSave = Object.assign({}, this.editModel);
      modelToSave.id = modelToSave._id;
      delete modelToSave.editType;
      delete modelToSave._id;
      delete modelToSave.updatedAt;
      delete modelToSave.createdAt;

      if(this.editModel.editType == 'new') {
        delete modelToSave.modelId;
        this.$axios.post('/make', modelToSave).then(function (response) {
          _this.editDialog = false;
          _this.fetchModels();
        }).catch(function(error) {
          console.error(error);
        })
      } else {
        this.$axios.put('/make', modelToSave).then(function (response) {
          _this.editDialog = false;
          _this.fetchModels();
        }).catch(function(error) {
          console.error(error);
        })
      }
      
    },
    openDeleteModel(model) {
      this.editModel = model;
      this.deleteDialog = true;
    },
    deleteModel() {
      let _this = this;
      this.$axios
        .delete('/make/' + this.editModel._id)
        .then(function (response) {
          console.log(response.data);
            _this.deleteDialog = false;
            _this.fetchModels();
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchModels() {
      var _this = this;
      this.$axios
        .get(
          "/make/model/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
        )
        .then(function (response) {
          _this.models = response.data.data;
          _this.lastCars = _this.models.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    initializeModel() {
        this.editModel = {
            make: '',
            model: '',
            _id: '',
            editType: '',
        }
    },
    autoComplete() {
      let _this = this;

      if(this.editModel.make.length > 0) {
        this.$axios.get('/make/search/' + this.editModel.make + '/0/5').then(function (response) {
          _this.autoCompleteResult = response.data.data;
          document.getElementById('autoComplete').setAttribute('style', 'visibility: visible;')
        }).catch(function (error) {
          console.error(error);
        })
      } else {
        this.hideAutoComplete();
      }
    },
    hideAutoComplete() {
      document.getElementById('autoComplete').setAttribute('style', 'visibility: hidden;')
    },
   syncWithExt() {
    return;
     let _this = this;
     this.$axios.post('/make/sync').then(function (response) {
       _this.fetchModels();
     }).catch(function(error){
       console.error(error);
     })
   },
   chooseAutoComplete(el) {
     this.autoCompleteSilent = true;
     this.editModel.make = el;
     Vue.nextTick(() => {
       this.autoCompleteSilent = false;
     });
   },
  },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>