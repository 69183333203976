<template>
  <div>
      	<!--Page header-->
        <div class="page-header">
            <div class="page-leftheader">
                <h4 class="page-title mb-0 text-primary">Dashboard</h4>
            </div>
            <div class="page-rightheader">
                <div class="btn-list">
                    
                    <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" id="dropdownToday" @click="fetchData('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" id="dropdownLastWeek" @click="fetchData('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item active" id="dropdownLastMonth" @click="fetchData('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item" id="dropdownAllTime" @click="fetchData('AllTime')">Gesamt</a>
                    </div>
                </div>
            </div>
        </div>
        <!--End Page header-->

        <!-- Row-1 -->
        <div class="row">
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash1">
                    <div class="card-body dashboardCard">
                        <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <span class="fs-14 font-weight-normal">Gesamte Nutzer</span>
                                    <h2 class="mb-2 number-font carn1 font-weight-bold">{{userAmount.amount}}</h2>
                                </div>
                                <div class="headerChartContainer">
                                <div class="chart-wrapper">
                                    <PieChart :chartData="userAmount.chartData" :chartOptions="userAmount.chartOptions" :chartStyle="userAmount.chartStyle"/>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>            
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash2">
                    <div class="card-body dashboardCard">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="">
                                    <span class="fs-14">Aktive Nutzer</span>
                                    <h2 class="mb-2 number-font carn1 font-weight-bold">{{activeUsers.sum}}</h2>
                                </div>
                            </div>
                            <div class="headerChartContainer">
                                <div class="chart-wrapper">
                                    <LineChart :chartData="activeUsers.chartData" :chartOptions="activeUsers.chartOptions" :chartStyle="activeUsers.chartStyle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash3">
                    <div class="card-body dashboardCard">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="">
                                    <span class="fs-14">Betriebssysteme</span>
                                </div>
                            </div>
                            <div class="headerChartContainer" style="margin-top:38.4px">
                                <div class="chart-wrapper">
                                    <PieChart :chartData="userOS.chartData" :chartOptions="userOS.chartOptions" :chartStyle="userOS.chartStyle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash2">
                    <div class="card-body dashboardCard">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="">
                                    <span class="fs-14">Trips</span>
                                    <h2 class="mb-2 number-font carn1 font-weight-bold">{{amountOfTrips.amount}}</h2>
                                </div>
                            </div>
                            <div class="headerChartContainer">
                                <div class="chart-wrapper">
                                    <PieChart :chartData="amountOfTrips.chartData" :chartOptions="amountOfTrips.chartOptions" :chartStyle="amountOfTrips.chartStyle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash4">
                    <div class="card-body dashboardCard">
                        <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <span class="fs-14 font-weight-normal">Neue Nutzer</span>
                                    <h2 class="mb-2 number-font carn1 font-weight-bold">{{registeredUsers.sum}}</h2>
                                </div>
                        </div>
                                
                            <div class="chart-wrapper headerChartContainer">
                                <LineChart :chartData="registeredUsers.chartData" :chartOptions="registeredUsers.chartOptions" :chartStyle="registeredUsers.chartStyle"/>
                            </div>
                                
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6">
                <div class="card overflow-hidden dash1-card border-0 dash1">
                    <div class="card-body dashboardCard">
                        <div class="row">
                                <div class="col-md-6 col-sm-6 col-6">
                                    <span class="fs-14 font-weight-normal">Gelöschte Nutzer</span>
                                    <h2 class="mb-2 number-font carn1 font-weight-bold">{{deletedUsers.sum}}</h2>
                                </div>
                        </div>
                                
                                    <div class="chart-wrapper headerChartContainer">
                                        <LineChart :chartData="deletedUsers.chartData" :chartOptions="deletedUsers.chartOptions" :chartStyle="deletedUsers.chartStyle"/>
                                    </div>
                                
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-1 -->

        <!-- Row-2 -->
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header border-bottom-0">
                        <h3 class="card-title">Verwendete App-Versionen</h3>
                    </div>
                    <div class="card-body pt-0">
                        <div class="chart-wrapper">
                            <BarChart v-bind:chartData="installedVersions.chartData" :options="installedVersions.chartOptions"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Eingelöste Belohungen
                        </h3>
                        <div class="card-options">
                            <a class="btn btn-sm btn-primary" @click="$router.push('rewardHistory')">Alle ansehen</a>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="card-body pt-0">
                        <div class="chart-wrapper">
                            <BarChart v-bind:chartData="sentRewards.chartData" :options="sentRewards.chartOptions"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-2 -->

        <!-- Row-3 -->
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header border-bottom-0">
                        <h3 class="card-title">Meist gefahrene Kilometer </h3>
                    </div>
                    <div class="card-body pt-0">
                        <div class="chart-wrapper">
                            <BarChart v-bind:chartData="topUsersByDistance.chartData" :options="topUsersByDistance.chartOptions"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Höchste Anzahl an Beiträgen
                        </h3>
                        <div class="card-options">
                            <a class="btn btn-sm btn-primary" @click="$router.push('rewardHistory')">Alle ansehen</a>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="card-body pt-0">
                        <div class="chart-wrapper">
                            <BarChart v-bind:chartData="topUsersByPost.chartData" :options="topUsersByPost.chartOptions"/>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-3 -->    

        <!-- Row-4 -->
        <div class="row row-deck">
            <div class="col-lg">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Letzte Beiträge
                        </h3>
                        <div class="card-options">
                            <a @click="$router.push('posts')" class="btn btn-sm btn-primary">Alle ansehen</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive table-hover clickable">
                            <table class="table card-table table-vcenter text-nowrap">
                                <thead>
                                    <th>Benutzername</th>
                                    <th>Bild</th>
                                    <th>Beschreibung</th>
                                    <th>Veröffentlichung</th>
                                </thead>
                                <tbody>
                                    <tr v-for="post in lastPosts" :key="post._id" @click="clickOnTableRow('Posts', { post: post })">
                                    <td class="align-middle">{{ post.user.userName }}</td>
                                    <td>
                                        <img :src="`${$awsLargeImages}/${post.galleryImages[0].url}`" alt="img" class="avatar avatar-md brround ">
                                    </td>
                                    <td class="align-middle tableAbout">{{ post.description }}</td>
                                    <td class="align-middle">{{ $formatDate(post.createdAt) }}</td>
                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

        <!-- Row-5 -->
        <div class="row row-deck">
            <div class="col-lg">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Letzte freigeschaltete Belohnungen
                        </h3>
                        <div class="card-options">
                            <a @click="$router.push('rewardHistory')" class="btn btn-sm btn-primary">Alle ansehen</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive table-hover">
                            <table class="table card-table table-vcenter text-nowrap">
                                <thead>
                                    <th>Titel</th>
                                    <th>Kategorie</th>
                                    <th>Benutzername</th>
                                    <th>Typ</th>
                                    <th>Coins</th>
                                    <th>Adresse</th>
                                    <th>Email</th>
                                    <th>Aktion</th>
                                </thead>
                                <tbody>
                                    <tr v-for="rewardObject in lastRewards.result" :key="rewardObject._id">
                                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.title }}</td>
                                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.category }}</td>
                                    <td class="align-middle">{{ rewardObject.userName == null ? '' : rewardObject.userName }}</td>
                                    <td class="align-middle">
                                        <div v-if="rewardObject.reward != null && rewardObject.reward.type=='address'"> Addresse</div>
                                        <div v-if="rewardObject.reward != null && rewardObject.reward.type=='email'"> Email</div>
                                    </td>
                                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.coins }}</td>
                                    <td class="align-middle"> {{ rewardObject.address == null ? '' :rewardObject.address }} </td>
                                    <td class="align-middle">{{ rewardObject.email == null ? '' : rewardObject.email }}</td>
                                    <td>
                                    <a
                                        class="btn btn-primary btn-small mr-1"
                                        @click="sendReward(rewardObject)"
                                        ><i class="fas fa-paper-plane"></i></a>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
  </div>
</template>

<script>

import BarChart from './charts/Bar.vue'
import LineChart from './charts/Line.vue'
import PieChart from './charts/Pie.vue'


export default {
    name: 'Dashboard',
   components: {
    BarChart,
    LineChart,
    PieChart,
   },


   data() {
       return {

           chartData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      chartOptions: {
        responsive: true
      },
        registeredUsers: {
            chartData: {},
        },
        installedVersions: {
            chartData: {},
        },
        activeUsers: {
            chartData: {},
        },
        topUsersByDistance: {
            chartData: {},
        },
        topUsersByPost: {
            chartData: {},
        },
        userOS: {
            chartData: {}
        },
        deletedUsers: {
        chartData: {},
        },
        lastPosts: [],
        lastRewards: [],
        sentRewards: {
            chartData: {},
        },
        userAmount: {
            amount: 0,
            chartData: {},
        },
        amountOfTrips: {
            amount: 0,
            chartData: {},
        },

        
        currentTimeSpan: 'LastMonth',
           
       }
   },

   mounted() {
       let _this = this;
       
       this.fetchData(this.currentTimeSpan);
   },

   watch: {
       registeredUsers: {
           handler() {
               if(this.registeredUsers.length > 0){
               }
           }
       }, 
       installedVersions: {
           handler() {
               
           }
       },
   },

   methods: {
       fetchData(timeSpan) {
           let _this = this;
           let time;
           this.setTimePicker(timeSpan);

           if(timeSpan == 'Today') {
               time = 1;
           } else if (timeSpan == 'LastWeek') {
               time = 7;
           } else if (timeSpan == 'LastMonth') {
               time = 30;
           } else if (timeSpan == 'AllTime') {
               time = -1;
           }

           this.fetchRegisteredUsers(time);
           this.fetchActiveUser(time);
           this.fetchInstalledVersions();
           this.fetchSystemInformation(time);
           this.fetchTopUserByPost(time);
           this.fetchTopUserByDistance(time);
           this.fetchAmountOfSentRewards(time),
           this.fetchLastPosts();
           this.fetchLastRewards(time);
       },
       fetchRegisteredUsers(time) {
           let _this = this;

           this.$axios.get('/user/registered/date/' + time).then(function (response) {
               _this.registeredUsers.result = response.data.data;
               let reg = new RegExp('[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]');
               const tempArr = _this.registeredUsers.result.filter(m => reg.test(m.validFor));


               const counts = tempArr.map(m => m.numberOfRegisteredUsers + m.numberOfRegisteredAnonymousUsers);
               const deleteCounts = tempArr.map(m => m.numberOfDeletedUsers + m.numberOfDeletedAnonymousUsers);
               const times = tempArr.map(m => _this.currentTimeSpan == 'AllTime' ? m.validFor : m.validFor.split('-')[2] + '-' + m.validFor.split('-')[1]);

               _this.registeredUsers.sum = counts.reduce((pv, cv) => pv + cv, 0);
               _this.deletedUsers.sum = deleteCounts.reduce((pv, cv) => pv + cv, 0);

               _this.registeredUsers.chartData = {
                   labels: times,
                   datasets: [{
                       label: 'Anzahl der registrierten Nutzer',
                       data: counts,
                       backgroundColor: 'rgba(223, 217, 245, 0.3)',
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }
               _this.registeredUsers.chartOptions = {
                   legend: {
                       labels: {
                           display: false
                       }
                   },
                   scales: {
                       yAxes: [{
                               beginAtZero: true,
                               ticks: {
                                   fontColor: 'white',
                               }
                       }],
                       xAxes: [{
                               fontColor: 'white',
                       }],
                   },
                    // responsive: true,
                    // maintainAspectRatio: false,
                    
                }

                _this.deletedUsers.chartData = {
                   labels: times,
                   datasets: [{
                       label: 'Anzahl der gelöschten Nutzer',
                       data: deleteCounts,
                       backgroundColor: 'rgba(223, 217, 245, 0.3)',
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }
               _this.deletedUsers.chartOptions = {
                   legend: {
                       labels: {
                           display: false
                       }
                   },
                   scales: {
                       yAxes: [{
                               beginAtZero: true,
                               ticks: {
                                   fontColor: 'white',
                               }
                       }],
                       xAxes: [{
                               fontColor: 'white',
                       }],
                   },
                    // responsive: true,
                    // maintainAspectRatio: false,
                    
                }
                _this.registeredUsers.chartStyle = {
                    
                }
           }).catch( function(error) {
               console.error(error);
           });
           this.$axios.get('/home/dashboard').then(function(response) {
            _this.userAmount.amount = response.data.data.users;
            _this.userAmount.chartData = {
                   labels: ["Anonyme Nutzer", "Normale Nutzer"],
                   datasets: [{
                       data: [response.data.data.anonymousUserAmount, response.data.data.notAnonymousUserAmount],
                       backgroundColor: '#664dc9',
                       backgroundColor: ['rgba(223, 217, 245, 0.3)', 'rgba(223, 217, 245, 0.6)'],
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }

            const tripByCategory = response.data.data.tripByCategory;
            const counts = tripByCategory.map(trip => trip.count);
            const labels = tripByCategory.map(trip => trip._id == "1 point" ? "1 Punkt" : trip._id == "2 point" ? "2 Punkte" : "Custom");
            _this.amountOfTrips.amount = counts.reduce((pv, cv) => pv + cv, 0);
            _this.amountOfTrips.chartData = {
                   labels: labels,
                   datasets: [{
                       data: counts,
                       backgroundColor: '#664dc9',
                       backgroundColor: ['rgba(223, 217, 245, 0.3)', 'rgba(223, 217, 245, 0.6)'],
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }
           }).catch(function(error){
            console.error(error);
           })
       },
        fetchActiveUser(time) {
            let _this = this;
            this.$axios.get('/user/active/amount/' + time).then(function (response) {
                _this.activeUsers.result = response.data.data;
                const counts = _this.activeUsers.result.map(m => m.numberOfActiveUsers);
                let reg = new RegExp('[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]');
               const times = _this.activeUsers.result.filter(m => reg.test(m.validFor)).map(m => m.validFor);

               _this.activeUsers.sum = counts.reduce((pv, cv) => pv + cv, 0);

               _this.activeUsers.chartData = {
                   labels: times,
                   datasets: [{
                       label: 'Anzahl der aktiven Nutzer',
                       data: counts,
                       backgroundColor: 'rgba(223, 217, 245, 0.3)',
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }
               _this.activeUsers.chartOptions = {
                   scales: {
                       yAxes: {
                               beginAtZero: true,
                       }
                   },
                    responsive: true,
                    maintainAspectRatio: false,
                }
                _this.activeUsers.chartStyle = {
                    height: '250px',
                }
            }).catch( function(error) {
                console.error(error);
            });
        },
       fetchInstalledVersions() {
           let _this = this;

           this.$axios.get('/user/versions').then(function (response) {
               _this.installedVersions.result = response.data.data;
               const count = _this.installedVersions.result.map(m => m.count);
               const labels = _this.installedVersions.result.map(m => m._id);

               _this.installedVersions.chartData = {
                   labels: labels,
                   datasets: [{
                       label: 'Anzahl der Benutzer pro Version',
                       data: count,
                       backgroundColor: '#664dc9',
                   }]
               }
               _this.installedVersions.chartOptions = {
                   scales: {
                       yAxes: [{
                           ticks: {
                                min: 0,
                                reverse: false,
                            },
                       }]
                   },
                }
            
           }).catch( function(error) {
               console.error(error);
           });
       },
       fetchSystemInformation(time) {
           let _this = this;

           this.$axios.get('/user/os/amount/' + time).then(function (response) {
               _this.userOS.result = response.data.data;
               _this.userOS.result = response.data.data;
               const count = _this.userOS.result.map(m => m.count);
               const labels = _this.userOS.result.map(m => m._id);

               _this.userOS.chartData = {
                   labels: labels,
                   datasets: [{
                       data: count,
                       backgroundColor: '#664dc9',
                       backgroundColor: ['rgba(223, 217, 245, 0.3)', 'rgba(223, 217, 245, 0.6)'],
                       borderColor: 'rgba(223, 217, 245, 0.8)',
                   }]
               }
               _this.userOS.chartOptions = {
                   scales: {
                       yAxes: [{
                           ticks: {
                                min: 0,
                                reverse: false,
                            },
                       }]
                   },
                }
           }).catch( function(error) {
               console.error(error);
           });
       },
       fetchTopUserByDistance(time) {
           let _this = this;
           this.$axios.get('/user/top/distance/' + time + '/0/10').then(function (response) {
               _this.topUsersByDistance.result = response.data.data;
               const drivenKilometers = _this.topUsersByDistance.result.map(m => m.drivenKilometers);
               const labels = _this.topUsersByDistance.result.map(m => m.userName);

               _this.topUsersByDistance.chartData = {
                   labels: labels,
                   datasets: [{
                       label: 'Anzahl der gefahrenen Kilometer',
                       data: drivenKilometers,
                       backgroundColor: '#664dc9',
                   }]
               }
               _this.topUsersByDistance.chartOptions = {
                   scales: {
                       yAxes: [{
                           ticks: {
                                min: 0,
                                reverse: false,
                            },
                       }]
                   },
                }
           }).catch( function(error) {
               console.error(error);
           });
       },
       fetchTopUserByPost(time) {
           let _this = this;
           this.$axios.get('/user/top/posts/' + time + '/0/10').then(function (response) {
               _this.topUsersByPost.result = response.data.data;
               const count = _this.topUsersByPost.result.map(m => m.count);
               const labels = _this.topUsersByPost.result.map(m => m._id);

               _this.topUsersByPost.chartData = {
                   labels: labels,
                   datasets: [{
                       label: 'Anzahl der geteilten Beiträge',
                       data: count,
                       backgroundColor: '#664dc9',
                   }]
               }
               _this.topUsersByPost.chartOptions = {
                   scales: {
                       yAxes: [{
                           ticks: {
                                min: 0,
                                reverse: false,
                            },
                       }]
                   },
                }
           }).catch( function(error) {
               console.error(error);
           });
       },
       fetchLastPosts() {
           let _this = this;
           this.$axios.get('/post/all/0/10/-1').then(function (response) {
               _this.lastPosts = response.data.data;
           }).catch( function(error) {
               console.error(error);
           });
       },
        fetchAmountOfSentRewards(time) {
           let _this = this;
           this.$axios.get('/reward/history/amount/' + time).then(function (response) {
               _this.sentRewards.result = response.data.data;
               const count = _this.sentRewards.result.map(m => m.count);
               const labels = _this.sentRewards.result.map(m => m._id.title);

               _this.sentRewards.chartData = {
                   labels: labels,
                   datasets: [{
                       label: 'Anzahl der eingelösten Belohnungen',
                       data: count,
                       backgroundColor: '#664dc9',
                   }]
               }
               _this.sentRewards.chartOptions = {
                   scales: {
                       yAxes: [{
                           ticks: {
                                min: 0,
                                reverse: false,
                            },
                       }]
                   },
                }
           }).catch( function(error) {
               console.error(error);
           });
       },
       fetchLastRewards(time) {
            let _this = this;
            this.$axios
                .post("/reward/history/all/0/10/" + time, { rewardSent: false })
                .then(function (response) {
                    _this.lastRewards.result = response.data.data;
                    const count = _this.lastRewards.result.map(m => m.count);
                    const labels = _this.lastRewards.result.map(m => m._id);

                    _this.lastRewards.chartData = {
                        labels: labels,
                        datasets: [{
                            label: 'test',
                            data: count,
                            backgroundColor: '#664dc9',
                        }]
                    }
                })
                .catch(function (error) {
                console.error(error);
                });
        },
        sendReward(rewardObject) {
            let _this = this;
            const rewardToSend = {
                rewardHistoryId: rewardObject._id,
                rewardSent: true,
            }

            this.$axios.post('/reward/history/status/update', rewardToSend).then(function(response) {
                _this.fetchLastRewards();
            }).catch(function(error){
                console.error(error);
            });
        },
       setTimePicker(timeSpan) {
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
       },
       clickOnTableRow(name, params) {
		if(this.$route.name == name) {
			if(name == 'Users'){
				this.$root.$emit('Users', params.user);
			} else if(name == 'Events') {
				this.$root.$emit('Events', params.event);
			} else if(name == 'Trips') {
				this.$root.$emit('Trips', params.trip);
			} else if(name == 'Posts') {
				this.$emit('Posts', params.post);
			} else if(name == 'Rewards') {
				this.$root.$emit('Rewards', params.reward);
			}
		} else {
			this.$router.push({name: name, params: params});
		}
	},
   }
}
</script>

<style>

</style>