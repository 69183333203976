import { render, staticRenderFns } from "./RewardHistory.vue?vue&type=template&id=1e96edd5&scoped=true"
import script from "./RewardHistory.vue?vue&type=script&lang=js"
export * from "./RewardHistory.vue?vue&type=script&lang=js"
import style0 from "./RewardHistory.vue?vue&type=style&index=0&id=1e96edd5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e96edd5",
  null
  
)

export default component.exports