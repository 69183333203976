<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="text-center mb-5">
                <img src="/images/brand/logo.png" class="header-brand-img desktop-lgo" alt="Azea logo">
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="text-center mb-3">
                    <h1 class="mb-2">Anmelden</h1>
                    <span>Willkommen zurück!</span>
                  </div>
                  <div class="mt-5">
                    <div class="input-group mb-4">
                        <div class="input-group-text" style="width: 44px !important;">
                          <i class="far fa-user" style="margin-left: 2px"></i>
                        </div>
                      <input v-model="credentials.email"  type="text" class="form-control" placeholder="E-Mail" @keypress.enter="login()">
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group" id="Password-toggle1">
                        <a class="input-group-text" @click="togglePasswordShow" style="width: 44px !important;">
                          <i v-if="inputType == 'password'" class="far fa-eye"></i>
                          <i v-else class="far fa-eye-slash"></i>
                        </a>
                        <input v-model="credentials.password" class="form-control" :type="inputType" placeholder="Passwort" @keypress.enter="login()">
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" />
                        <span class="custom-control-label">Angemeldet bleiben</span>
                      </label>
                    </div>
                    <div class="form-group text-center mb-3">
                      <button class="btn btn-primary btn-lg w-100 br-7" @click="login()">Anmelden</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routingTarget: String,
  },

  data() {
    return {
      showPassword: false,
      inputType: "password",

      credentials: {
        email: "",
        password: ""
      },
    };
  },

  mounted() {
    this.setLoginBodySettings(true);
    // this.$axios.checkBearer().then(this.onLoggedIn).catch(this.discardError);
  },

  methods: {
    login() {
      var _this = this;
      this.$axios.credentials = this.credentials;
      // this.$axios.authorize().then(this.onLoggedIn).catch(this.onLoginFailed);
      this.$axios.post('/signInDashboard', {
        email: this.credentials.email,
        password: this.credentials.password,
        fcmToken: ""
      }).then(function(response){
        localStorage.setItem("id_token", response.data.data.token);
        _this.onLoggedIn(response);
      }).catch(this.onError);
    },
    onLoggedIn(response) {
      this.$axios
        .get("/user/profile/my/0/5")
        .then(this.onSuccessfulLogin)
        .catch(this.onLoginFailed);
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error("Email oder Passwort sind nicht korrekt!");
      } else {
        console.error("Wrong Credentials");
      }
    },
    onSuccessfulLogin(response){
      Vue.prototype.$user = response.data.data;
      this.setLoginBodySettings(false);
      if(this.routingTarget != null){
        this.$router.push(this.routingTarget);
        window.location.reload();
        return;
      }
      this.$router.push("dashboard");
    },
    onLoginFailed(error) {
      this.$noty.error(this.$t("ERROR_INVALID_CREDENTIALS"));
      showLoadingCircle(false);
    },
    logError(error) {
      console.log(error);
    },
    discardError(error) {},

    setLoginBodySettings(bool){
      if(bool){
        document.getElementById("app").classList.add("login");
      }else{
        document.getElementById("app").classList.remove("login");
      }
    },
    togglePasswordShow() {
      if(this.inputType == "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    }
  },
};
</script>

<style>
</style>