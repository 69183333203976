<script>
/**
 * @author CodeGuides GmbH, alle Rechte vorbehalten
 */
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    }
    
  },
  mounted () {
    if(this.chartData != null && this.chartData.datasets != null && this.chartData.datasets.length > 10){
      this.options.legend = {
        display: false,
      };
    }
    this.renderChart(this.chartData, this.options)
  },
  methods:{
    updateChart(){
      this.renderChart(this.chartData, this.options)
    },
    handle (point, event) {
      const item = event[0];
      console.log(item);
    	this.$emit('on-receive', {
      	index: item._index,
        backgroundColor: item._view.backgroundColor,
        value: this.values[item._index]
      })
    }
  }
}


</script>

<style>

</style>
