<template>
  <div>
    <!-- <GmapAutocomplete class="form-control" @place_changed="setPlace" :value="value ? value.place : null" v-bind:class="{ 'is-invalid' :  validated }" :disabled="disabled"/> -->
    <GmapAutocomplete class="form-control" @place_changed="setPlace" :value="value ? value.place : null" :disabled="disabled"/>
    <br />
    <GmapMap
      :center="center"
      :zoom="12"
      style="width: 100%; height: 400px"
      @open-dialog="setPlaceWithCoords"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    value: Object, // for v-model Binding
    validated: Boolean, // if validated == false, the Autocomplete is marked as invalid
    disabled: Boolean, 
  },

  computed: {
    currentPlace: {
      get() {
        return this.value;
      },
      set(val) {
        const value = this.getFormattedAddress(val);

        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      center: {},
      markers: [],

      // center: { lat: 10, lng: 10},
    };
  },
  mounted() {    
    if(this.value && this.value.location && this.value.location.coordinates){
      this.center =  {lat: this.value.location.coordinates[0], lng: this.value.location.coordinates[1]};
      this.addMarkerWithCoordinates(this.value);
    } else {
      this.geolocate();
    }
    // this.geolocate();

  },
  methods: {
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    setPlace(place) {
      this.currentPlace = place;
      this.center = place.geometry.location;
      this.addMarker(place);
    },
    setPlaceWithCoords(eventAddress) {
      // Set Place using recieved coordinates
    },
    getFormattedAddress(googlePlace) {
      this.location = {
        type: "Point",
        coordinates: [
          googlePlace.geometry.location.lat(),
          googlePlace.geometry.location.lng(),
        ],
        place: googlePlace.formatted_address,
        city: "",
        state: "",
        country: "",
      };
      googlePlace.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          this.location.city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          this.location.state = component.long_name;
        } else if (component.types.includes("country")) {
          this.location.country = component.long_name;
        }
      });
      
      return this.location;
    },
    addMarker(place) {
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.markers.push({ position: marker });
      this.center = marker;
      this.currentPlace = null;
    },
    addMarkerWithCoordinates(value) {
      const marker = {
        lat: value.location.coordinates[0],
        lng: value.location.coordinates[1]
      };
      this.markers.push({ position: marker });
      this.center = marker;
      this.currentPlace = null;
    },
    test() {
      this.testCoords = {lat: 0, lng: 0}
    }
  },
};
</script>
