<template>
<div>
  <div class="page-header">
    <div class="page-leftheader">
      <div class="headline"><h4 class="page-title text-primary">Alle fertigen Trips</h4 ></div>
    </div>
    <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditTrip(editTrip, 'new')"
          >Trip hinzufügen</a
        >
      <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item active" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
                    </div>
    </div>
    
  </div>
  <div>
    <div>
        <input v-model="search" type="search" class="form-control form-control-sm" placeholder="Suche..." aria-controls="example1">
        <br>
    </div> 
    <div>
           
      <div>
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Bild</th>
                  <th>Name</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="trip in trips" :key="trip._id">
                <td class="align-middle">
                    <img
                      :src="`${$awsLargeImages}/${trip.image}`"
                      alt="img"
                      class="avatar avatar-md brround"
                    />
                </td>
                <td class="align-middle">{{trip.title}}</td>
                <td>
                    <a
                      title="Trip bearbeiten"
                      class="btn btn-primary btn-small mr-1"
                      @click="openEditTrip(trip, 'edit')"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a
                      title="Trip löschen"
                      class="btn btn-small btn-danger"
                      @click="openDeleteTrip(trip)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
              </tr>
            </tbody>
            </table>
          </div>
          <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
        </div>
      </div>
      <v-dialog
        v-model="editDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card">
            <h4 class="page-title text-primary">Trip Bearbeiten</h4>
          </v-card-title>
          <v-card-text>
              <div class="form-group">
              <label class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Name"
                v-model="editTrip.title"
                v-bind:class="{'is-invalid': emptyValidation && (editTrip.title == null || editTrip.title == '')}"
              />
            </div>

            <div class="form-group">
              <label class="form-label">Bild</label>
              <div class="card overflow-hidden shadow-none border" v-if="this.editTrip.editType == 'edit'">
                  <img :src="`${$awsLargeImages}/${editTrip.image}`" alt="image" class="card-image1 ">
              </div>
              <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
              </div>
              <div class="input-group file-browser mb-5 mt-4">
                <input
                  type="text"
                  class="form-control browse-file"
                  id="imageFileDisplay"
                  placeholder=""
                  readonly=""
                  v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editTrip.image == null || editTrip.image == '')}"
                />
                <label class="input-group-text btn btn-primary">
                  Browse
                  <input
                    id="imageFile"
                    type="file"
                    class="file-browserinput"
                    style="display: none"
                    multiple=""
                    accept="image/png, image/jpeg"
                    v-on:change="setFileName()"
                  />
                </label>
              </div>
          </div>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
          <a class="btn btn-primary btn-pill" @click="saveTrip(trip)">Speichern</a>
        </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog 
        v-model="deleteDialog"
        width="500">
        <v-card>
          <v-card-title class="card">
              <h4 class="page-title text-primary">Trip löschen</h4>
            </v-card-title>
            <v-card-text>
              Sind sie sicher, dass sie diesen Trip löschen wollen?
            </v-card-text>
            <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="closeDeleteTrip(editTrip)">Löschen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
      
      </div>
    </div>
  
</div>
</template>

<script>
export default {

  components: {
  },

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,
      routes: {
        tenants: "/api/tenant",
        filter: "/api/tenants/search/"
      },
      search: "",
      convertedTenants: [],
      tenantFilter: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      tenants: [
        {
          name: '',

        }
      ],
      roles: [],
      createTenantPopup: false,
      menu: false,
      createmode: true,

      // Personal Data
      me: null,

    trips: [],
    editTrip: {},
    trip: '',
    updatedPic: '',
    

    editDialog: false,
    deleteDialog: false,
    emptyDialog: false,
    emptyValidation: false,

    currentTimeSpan: 'AllTime'
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchTrip();
      } else {
        this.page = 0;
        this.searchTrip();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchTrip();
    console.log(this.trips);
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastTrips == this.ElementPerPage || this.lastTrips == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/trip/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
                          .then(function (response){
                            _this.lastTrips = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.trips.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/trip/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
                      _this.lastTrips = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.trips.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchTrip() {
      var _this = this;
      this.$axios.get('/trip/presetTrip/all/0/'+ this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
        _this.trips = response.data.data;
        _this.lastTrips = response.data.data.length;
        console.log(response.data.data);
      }).catch(function(error){
        console.error(error);
      });
    },
    searchTrip() {
      var _this = this;
      this.$axios
        .get(
          "/trip/presetTrip/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
             + '/' + this.getCurrentTimeSpan()
        )
        .then(function (response) {
          _this.trips = response.data.data;
          _this.lastTrips = _this.trips.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openEditTrip(trip, type) {
      this.editTrip = trip;
      this.editTrip.editType = type;
      if(type == "new") {
        this.initializeTrip();
      }
      this.editDialog = true;
    },
    openDeleteTrip(trip) {
      this.editTrip = trip;
      this.deleteDialog = true;
    },
    saveTrip() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        _this.saveTripToServer();
      });
    },
    saveTripToServer() {
      let _this = this;
      if(document.getElementsByClassName('is-invalid').length > 0) {
        this.emptyDialog = true;
        return;
      }

      let fileToUpload;
      const tripToSave = Object.assign({}, this.editTrip);
      tripToSave.presetTripId = tripToSave._id;

      delete tripToSave._id;
      delete tripToSave.status;
      delete tripToSave.user;
      delete tripToSave.__v;
      delete tripToSave.createdAt;
      delete tripToSave.updatedAt;
      delete tripToSave.editType;
      

      if(document.getElementById('imageFile').files.length > 0 ) {
        if(((document.getElementById('imageFile').files[0].size/1024)/1024).toFixed(4) > 20) {
          this.$showErrorFileSize(20);
          return;
        }
        fileToUpload = document.getElementById('imageFile').files[0];
        delete tripToSave.image;
      }

      if(this.editTrip.editType == 'new') {
        delete tripToSave.presetTripId;
        const body = this.$formatUrlEncoded(tripToSave, fileToUpload);
        this.$axios.post('/trip/presetTrip/add', body).then(function(response){
          console.log(response.data);
          _this.editDialog = false;
        }).catch(function(error){
          console.error(error);
        });  
      } else {
        const body = this.$formatUrlEncoded(tripToSave, fileToUpload);
        this.$axios.put('/trip/presetTrip/update', body).then(function(response){
          console.log(response.data);
          _this.editDialog = false;
        }).catch(function(error){
          console.error(error);
        });
      }
    },
    initializeTrip() {
        this.editTrip = {
        _id: "",
        createdAt: "",
        image: "",
        status: "",
        title: "",
        updatedAt: "",
        user: "",
      };
      this.updatedPic = '';
    },
    checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    },
    setFileName() {
       let path = document.getElementById('imageFile').value;
       console.log(path);
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
    },
    setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchTrip();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    }
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

