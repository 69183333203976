<template>
<div>
  <div class="page-header">
    <div class="page-leftheader">
      <div class="headline"><h4 class="page-title text-primary">Benutzer</h4 ></div>
    </div>
    <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('users')"
          >zurück</a
        >
        <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
        <div class="dropdown-menu border-0">
                <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                <a class="dropdown-item active" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                <a class="dropdown-item" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
        </div>
    </div>
    
  </div>
  <div>
    <div>
        <input v-model="search" type="search" class="form-control form-control-sm" placeholder="Suche..." aria-controls="example1">
        <br>
    </div> 
    <div>
      <div>
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Telefonnummer</th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="user in users" :key="user._id">
                <td class="align-middle">{{user.email}}</td>
                <td class="align-middle">{{user.telephoneNumber}}</td>
                <td>
                  <a class="btn btn-small btn-success" @click="openDeleteUser(user)" title="Benutzer wieder freischalten"><i class="fas fa-check"></i></a>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      </div>
      </div>

      <!-- Dialog Nutzer löschen -->
      <v-dialog
        v-model="deleteDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card ">
            Benutzer löschen
          </v-card-title>

          <v-card-text>
            <label class="form-label">Möchten sie den Benutzer wirklich von der Blacklist löschen?</label>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteUser(editUser)">Löschen</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  
</div>
</template>

<script>
export default {

  components: {
  },

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      lastUsers: null,
      sort: null,
      order: true,
      routes: {
      },
      search: "",
      users: [],
      roles: [],

      menu: false,
      createmode: true,

      // Personal Data
      me: null,

      editUser: {
        userName: "",
        email: "",
        dateOfBirth: "",
        image: "",
        about: "",
        telephoneNumber: "",
        language: "",
        currentCoins: "",
        drivenKilometers: "",
        status: "",
        dateOfBirthObject: "",
      },

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,

      emptyValidation: false,
      deleteReason: '',
      currentTimeSpan: 'LastMonth'
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search : function(){
      if(this.search == ""){
        this.fetchUser();
      }else{
        console.log(this.search);
        this.page = 0;
        this.searchUser()
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchUser();
    if(this.$route.params.user) {
      this.openEditUser(this.$route.params.user, 'edit');
    }
    this.$root.$on('Users', (user) => {
      this.openEditUser(user, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastUsers == this.ElementPerPage || this.lastUsers == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/user/blacklist/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
                          .then(function (response){
                            _this.lastUsers = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.users.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/user/blacklist/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
                      _this.lastUsers = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.users.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchUser() {
      var _this = this;
      this.$axios.get('/user/blacklist/all/0/'+ this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
        _this.users = response.data.data;
        _this.lastUsers = response.data.data.length;
      }).catch(function(error){
        console.error(error);
      });
    },
    searchUser(){
      var _this = this;
      this.$axios.get('/user/blacklist/search/' + this.search +'/'+ this.page + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
        _this.users = response.data.data;
        _this.lastUsers = _this.users.length;
      }).catch(function(error){
        console.error(error);
      });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openDeleteUser(user) {
      this.deleteDialog = true;
      this.editUser = user;
    },
    deleteUser() {
      let _this = this;
      this.$axios.get('user/blacklist/delete/' + this.editUser._id).then(function(response){
        _this.deleteDialog = false;
        _this.fetchUser();
      }).catch(function(error){
        console.error(error);
      })
    },
  setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchUser();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    }
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

