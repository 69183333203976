<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Modelle</h4>
        </div>
      </div>
      <div class="page-rightheader">
          <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditModel(editModel, 'new')"
          >Modell hinzufügen</a
        >
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('manufacturers')"
          >Automarken</a
        >
        <a
          class="btn btn-primary btn-pill"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-calendar me-2 fs-14"></i> Search By Date</a
        >
        <div class="dropdown-menu border-0">
          <a class="dropdown-item">Today</a>
          <a class="dropdown-item">Yesterday</a>
          <a class="dropdown-item active"
            >Last 7 days</a
          >
          <a class="dropdown-item">Last 30 days</a>
          <a class="dropdown-item">Last Month</a>
          <a class="dropdown-item">Last 6 months</a>
          <a class="dropdown-item">Last year</a>
        </div>
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Search..."
          aria-controls="example1"
        />
        <br />
      </div>
      <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                      <th>Bild</th>
                      <th>Modell</th>
                      <th>Marke</th>
                      <th>Jahr</th>
                      <th>Aktion</th>
                  </thead>
                  <tbody>
                    <tr v-for="model in models" :key="model._id">
                    <td class="align-middle">
                        <img
                        :src="`${$awsLargeImages}/${model.galleryImages[0].url}`"
                        alt="img"
                        class="avatar avatar-md brround"
                        />
                    </td>
                    <td class="align-middle">{{ model.model }}</td>
                    <td class="align-middle">{{ model.brand }}</td>
                    <td class="align-middle">{{ model.year}}</td>
                    
                    <td>
                      <a
                      class="btn btn-primary btn-small mr-1"
                      @click="openEditModel(model, 'edit')"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a
                      class="btn btn-small btn-danger"
                      @click="openDeleteModel(model)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                    </td>
                      </tr>
                  </tbody>
              </table>
          </div>

      </div>
      <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
    </div>

    <!-- V-Dialoge -->
    <v-dialog 
        v-model="editDialog"
        width="500">
        <v-card>
         <v-card-title class="card">
            <h4 class="page-title text-primary" v-if="editModel.editType == 'edit'">Modell bearbeiten</h4>
            <h4 class="page-title text-primary" v-if="editModel.editType == 'new'">Modell hinzufügen</h4>
          </v-card-title>
          <v-card-text>
            <div class="form-group">
                <label class="form-label">Modell</label>
                <input type="text" class="form-control" placeholder="Modell..." v-model="editModel.model">
            </div>
            <div class="form-group">
                <label class="form-label">Marke</label>
                <input type="text" class="form-control" placeholder="Marke..." v-model="editModel.brand">
            </div>
            <div class="form-group">
                <label class="form-label">Jahr</label>
                <input type="text" class="form-control" placeholder="Jahr..." v-model="editModel.year">
            </div>
            <div class="form-group">
                <label class="form-label">Beschreibung</label>
                <textarea
                  rows="4"
                  class="form-control"
                  placeholder="Beschreibung"
                  v-bind:class="{
                    'is-invalid':
                      editModel.description == null ||
                      editModel.description == '',
                  }"
                  v-model="editModel.description"
                ></textarea>
            </div>
            <!-- <div id="carousel-controls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" v-for="img in editModel.galleryImages" :key="img.url">
                        <img class="d-block w-100" alt="" :src="`${$awsLargeImages}/${img.url}`" data-holder-rendered="true">
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-controls" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-controls" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </a>
            </div> -->
          </v-card-text>
            <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="openEditModel()">Speichern</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog 
        v-model="deleteDialog"
        width="500">
         <v-card-title class="card">
            <h4 class="page-title text-primary" v-if="editModel.editType == 'edit'">Modell bearbeiten</h4>
            <h4 class="page-title text-primary" v-if="editModel.editType == 'new'">Modell hinzufügen</h4>
          </v-card-title>
          <v-card-text>
            Sind sie sicher, dass sie dieses Ereigniss löschen wollen?
          </v-card-text>
                    <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteModel()">Löschen</a>
          </v-card-actions>
      </v-dialog>

  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      models: [],
      editModel: '',


      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchModels();
      } else {
        this.page = 0;
        this.searchModels();
      }
    },
  },

  mounted() {
    this.fetchModels();
    
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastCars == this.ElementPerPage || this.lastCars == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/auto/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage)
                          .then(function (response){
                            _this.lastCars = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.models.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/auto/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage).then(function(response){
                      _this.lastCars = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.models.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchModels() {
      var _this = this;
      this.$axios
        .get("/auto/all/0/" + this.ElementPerPage)
        .then(function (response) {
          _this.models = response.data.data;
          _this.lastModels = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    openEditModel(model, type) {
        this.initializeModel();
        if(type == 'edit') {
            this.editModel = model;
        }
        this.editModel.editType = type;
        this.editDialog = true;
    },
    searchModels() {
      var _this = this;
      this.$axios
        .get(
          "/auto/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
        )
        .then(function (response) {
          _this.models = response.data.data;
          _this.lastCars = _this.models.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    
    initializeModel() {
        this.editModel = {
            brand: '',
            model: '',
            year: '',
            description: '',
            _id: '',
            galleryImages: [],
            editType: '',
        }
    }

   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>