<template>
  <div id="app">
    <v-app>
      <div class="page-main">

		<!--aside open-->
		<aside v-if="$user != null" class="app-sidebar">
			<div class="app-sidebar__logo">
				<a class="header-brand" @click="$router.push('dashboard')">
					<img src="/images/brand/logo.png" class="header-brand-img desktop-lgo" alt="Azea logo">
					<img src="/images/brand/logo.png" class="header-brand-img dark-logo" alt="Azea logo">
					<img src="/images/brand/favicon.png" class="header-brand-img mobile-logo" alt="Azea logo">
					<img src="/images/brand/favicon1.png" class="header-brand-img darkmobile-logo" alt="Azea logo">
				</a>
			</div>
			<ul class="side-menu app-sidebar3">
				<li class="side-item side-item-category">Übersicht</li>
				<li class="slide">
					<a class="side-menu__item" @click="$router.push('dashboard')">
						<v-icon class="side-menu__icon" size="18">fas fa-home</v-icon>
					<span class="side-menu__label">Dashboard</span></a>
				</li>
				<li class="side-item side-item-category">Verwaltung</li>
				<li class="slide">
					<a class="side-menu__item" @click="$router.push('users')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-user</v-icon>
					<span class="side-menu__label">Benutzer</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('broadcast')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-bell</v-icon>
					<span class="side-menu__label">Broadcast</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('newsmessages')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-newspaper</v-icon>
					<span class="side-menu__label">Neuigkeiten</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('posts')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">far fa-image</v-icon>
					<span class="side-menu__label">Beiträge</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('events')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-star</v-icon>
					<span class="side-menu__label">Ereignisse</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('predefinedTrips')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-plus-circle</v-icon>
					<span class="side-menu__label">Fertige Trips</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('trips')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-plus-circle</v-icon>
					<span class="side-menu__label">Trips</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('reported')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-exclamation-triangle</v-icon>
					<span class="side-menu__label">Gemeldet</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('rewards')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-shopping-cart</v-icon>
					<span class="side-menu__label">Belohnungen</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('levels')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-dumbbell</v-icon>
					<span class="side-menu__label">Level</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('models')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-car</v-icon>
					<span class="side-menu__label">Autos/Marken</span><i class="angle fe fe-chevron-right"></i></a>
					<a class="side-menu__item" @click="$router.push('versions')" data-bs-toggle="sidebar-mobile" href="javascript:void(0);">
						<v-icon class="side-menu__icon" size="18">fas fa-wrench</v-icon>
					<span class="side-menu__label">App Versionen</span><i class="angle fe fe-chevron-right"></i></a>
				</li>
			</ul>
		</aside>
		<!--aside closed-->

		<!-- App-Content -->
		<div class="app-content main-content">
			<div class="side-app">

				<!--app header-->
				<div v-if="$user != null" class="app-header header main-header1">
					<div class="container-fluid">
						<div class="d-flex">
							<a class="header-brand" href="index.html">
								<img src="/images/brand/logo.png" class="header-brand-img desktop-lgo" alt="ETS logo">
								<img src="/images/brand/logo.png" class="header-brand-img dark-logo" alt="ETS logo">
								<img src="/images/brand/favicon.png" class="header-brand-img mobile-logo" alt="Azea logo">
								<img src="/images/brand/favicon1.png" class="header-brand-img darkmobile-logo" alt="Azea logo">
							</a>
							<div class="app-sidebar__toggle d-flex" data-bs-toggle="sidebar">
								<a class="open-toggle" href="javascript:void(0);">
									<svg xmlns="http://www.w3.org/2000/svg" class="feather feather-align-left header-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"/></svg>
								</a>
							</div>
							<div class="mt-1 d-md-block d-none">
								<form class="form-inline">
									<div class="search-element">
										<input type="search" class="form-control header-search mobile-view-search" placeholder="Search…" aria-label="Search" tabindex="1" v-model="search">
										<a class="btn btn-primary btn-lg" @click="closeSearchMenu()" v-if="search.length > 1"><i class="fas fa-times"></i></a>
										<button class="btn btn-primary-color" v-if="search.length < 2" >
											<svg xmlns="http://www.w3.org/2000/svg" class="header-icon search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/></svg>
										</button>
									</div>
								</form>
							</div><!-- SEARCH -->
							<div class="d-flex order-lg-2 ms-auto main-header-end">
								<button  class="navbar-toggler navresponsive-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="true" aria-label="Toggle navigation">
									<i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
								</button>
								<div class="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
									<div class="collapse navbar-collapse" id="navbarSupportedContent-4">
										<div class="d-flex order-lg-2">
											<div class="dropdown d-lg-none d-flex responsive-search">
												<a href="javascript:void(0);" class="nav-link icon" data-bs-toggle="dropdown">
													<svg xmlns="http://www.w3.org/2000/svg" class="header-icon search-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/></svg>
												</a>
												<div class="dropdown-menu header-search dropdown-menu-start">
													<div class="input-group w-100 p-2">
														<input type="text" class="form-control" placeholder="Suche...." v-model="search">
														<button class="btn btn-primary-color" @click="closeSearchMenu();log()" type="submit">
															<svg xmlns="http://www.w3.org/2000/svg" class="header-icon search-icon p-1 mt-1" width="24" height="24" viewBox="0 0 24 24"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/></svg>
														</button>
													</div>
												</div>
											</div><!-- SEARCH -->
											<!-- Fullscreen-Layout -->
											<div class="dropdown  header-fullscreen d-flex" >
												<a  class="nav-link icon full-screen-link p-0" @click="openFullscreen()">
													<svg xmlns="http://www.w3.org/2000/svg" class="header-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"/></svg>
												</a>
											</div>
											<div class="dropdown header-message d-flex">
												<a class="nav-link icon" data-bs-toggle="dropdown">
													<svg xmlns="http://www.w3.org/2000/svg" class="header-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"/></svg>
													<span class="badge bg-success side-badge">5</span>
												</a>
												<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow  animated">
													<div class="dropdown-header">
														<h6 class="mb-0">Messages</h6>
														<span class="badge fs-10 bg-secondary br-7 ms-auto">New</span>
													</div>
													<div class="header-dropdown-list message-menu">
														<a class="dropdown-item border-bottom" href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/1.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class="mb-1 fs-13">Joan Powell</span>
																		<p class="fs-12 mb-1">All the best your template awesome</p>
																		<div class="fs-11 text-muted">
																			3 hours ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
														<a class="dropdown-item border-bottom" href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/2.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class="mb-1 s-13">Gavin Sibson</span>
																		<p class="fs-12 mb-1">Hey! there I'm available</p>
																		<div class="fs-11 text-muted">
																			5 hour ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
														<a class="dropdown-item border-bottom" href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/3.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class="mb-1">Julian Kerr</span>
																		<p class="fs-12 mb-1">Just created a new blog post</p>
																		<div class="fs-11 text-muted">
																			45 mintues ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
														<a class="dropdown-item border-bottom" href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/4.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class=" fs-13 mb-1">Cedric Kelly</span>
																		<p class="fs-12 mb-1">Added new comment on your photo</p>
																		<div class="fs-11 text-muted">
																			2 days ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
														<a class="dropdown-item border-bottom"  href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/6.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class="mb-1 fs-13">Julian Kerr</span>
																		<p class="fs-12 mb-1">Your payment invoice is generated</p>
																		<div class="fs-11 text-muted">
																			3 days ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
														<a class="dropdown-item" href="chat.html">
															<div class="d-flex align-items-center">
																<div class="">
																	<span class="avatar avatar-md brround align-self-center cover-image" data-image-src="/images/users/7.jpg"></span>
																</div>
																<div class="d-flex mt-1 mb-1">
																	<div class="ps-3">
																		<span class="mb-1 fs-13">Faith Dickens</span>
																		<p class="fs-12 mb-1">Please check your mail....</p>
																		<div class="fs-11 text-muted">
																			4 days ago
																		</div>
																	</div>
																</div>
															</div>
														</a>
													</div>
													<div class=" text-center p-2 pt-3 border-top">
														<a href="chat.html" class="fs-13 btn btn-primary btn-md btn-block">See More</a>
													</div>
												</div>
											</div>
											<div class="dropdown header-notify d-flex">
												<a class="nav-link icon" data-bs-toggle="dropdown">
													<svg xmlns="http://www.w3.org/2000/svg" class="header-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"/></svg><span class="pulse "></span>
												</a>
												<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
													<div class="dropdown-header">
														<h6 class="mb-0">Notifications</h6>
														<span class="badge fs-10 bg-secondary br-7 ms-auto">New</span>
													</div>
													<div class="notify-menu">
														<a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
															<div class="notifyimg  text-primary bg-primary-transparent border-primary"> <i class="fa fa-envelope"></i> </div>
															<div>
																<span class="fs-13">Message Sent.</span>
																<div class="small text-muted">3 hours ago</div>
															</div>
														</a>
														<a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
															<div class="notifyimg  text-secondary bg-secondary-transparent border-secondary"> <i class="fa fa-shopping-cart"></i></div>
															<div>
																<span class="fs-13">Order Placed</span>
																<div class="small text-muted">5  hour ago</div>
															</div>
														</a>
														<a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4">
															<div class="notifyimg  text-danger bg-danger-transparent border-danger"> <i class="fa fa-gift"></i> </div>
															<div>
																<span class="fs-13">Event Started</span>
																<div class="small text-muted">45 mintues ago</div>
															</div>
														</a>
														<a href="email-inbox.html" class="dropdown-item border-bottom d-flex ps-4 mb-2">
															<div class="notifyimg  text-success  bg-success-transparent border-success"> <i class="fa fa-windows"></i> </div>
															<div>
																<span class="fs-13">Your Admin lanuched</span>
																<div class="small text-muted">1 daya ago</div>
															</div>
														</a>
													</div>
													<div class=" text-center p-2">
														<a href="email-inbox.html" class="btn btn-primary btn-md fs-13 btn-block">View All</a>
													</div>
												</div>
											</div>

											<div class="dropdown profile-dropdown d-flex">
												<a href="javascript:void(0);" class="nav-link pe-0 leading-none" data-bs-toggle="dropdown">
													<span class="header-avatar1">
														<img :src="`${$awsSmallImages}/${$user.image}`" alt="img" class="avatar avatar-md brround">
													</span>
												</a>
												<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
													<div class="text-center">
														<div class="text-center user pb-0 font-weight-bold">{{ $user.userName }}</div>
														<span class="text-center user-semi-title">{{ $user.email }}</span>
														<div class="dropdown-divider"></div>
													</div>
													<!-- <a class="dropdown-item d-flex" href="profile-1.html">
														<svg class="header-icon me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
														<div class="fs-13">Profile</div>
													</a>
													<a class="dropdown-item d-flex" href="search.html">
														<svg class="header-icon me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
														<div class="fs-13">Settings</div>
													</a>
													<a class="dropdown-item d-flex" href="chat.html">
														<svg class="header-icon me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z"/></svg>
														<div class="fs-13">Messages</div>
													</a> -->
													<a class="dropdown-item d-flex" @click="logout()">
														<svg class="header-icon me-2" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/></g></svg>
														<div class="fs-13">Abmelden</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--/app header-->
				<!-- global Search -->
				<v-card id="megaMenuCard"  class="pl-5 pr-5 pt-2">
						<b-container class="p-0">
							<b-row>
								<b-col>
									<label class="custom-control custom-checkbox">
										<input v-model="checkBoxes.user" type="checkbox" class="custom-control-input"/>
										<span class="custom-control-label">Benutzer</span>
									</label>
									<!-- <input type="checkbox" id="checkboxUser" ><label for="checkboxUser" class="text-light ml-1">Benutzer</label> -->
								</b-col>
								<b-col>
									<label class="custom-control custom-checkbox">
										<input v-model="checkBoxes.events" type="checkbox" class="custom-control-input"/>
										<span class="custom-control-label">Ereignisse</span>
									</label>
									<!-- <input type="checkbox" id="checkboxEvents" v-model="checkBoxes.events"><label for="checkboxEvents" class="text-light ml-1">Ereignisse</label> -->
								</b-col>
								<b-col>
									<label class="custom-control custom-checkbox">
										<input v-model="checkBoxes.trips" type="checkbox" class="custom-control-input"/>
										<span class="custom-control-label">Trips</span>
									</label>
									<!-- <input type="checkbox" id="checkboxTrips" v-model="checkBoxes.trips"><label for="checkboxTrips" class="text-light ml-1">Trips</label> -->
								</b-col>
								<b-col>
									<label class="custom-control custom-checkbox">
										<input v-model="checkBoxes.posts" type="checkbox" class="custom-control-input"/>
										<span class="custom-control-label">Beiträge</span>
									</label>
									<!-- <input type="checkbox" id="checkboxPosts" v-model="checkBoxes.posts"><label for="checkboxPosts" class="text-light ml-1">Beiträge</label> -->
								</b-col>
								<b-col>
									<label class="custom-control custom-checkbox">
										<input v-model="checkBoxes.rewards" type="checkbox" class="custom-control-input"/>
										<span class="custom-control-label">Belohnungen</span>
									</label>
									<!-- <input type="checkbox" id="checkboxRewards" v-model="checkBoxes.rewards"><label for="checkboxRewards" class="text-light ml-1">Belohnungen</label> -->
								</b-col> 
								
							</b-row>
						</b-container>
					<div class="card" v-if="checkBoxes.user">
						<v-card-title>Benutzer</v-card-title>
						<div class="table-responsive table-hover" v-if="userSearch.length > 0">
							<table class="table card-table table-vcenter text-nowrap">
								<thead>
									<th>Profilbild</th>
									<th>Benutzername</th>
									<th>Email</th>
								</thead>
								<tbody>
									<tr class="clickableTable " v-for="user in userSearch" :key="user._id" @click="openSearchResult('Users', {user: user})">
										<td class="align-middle"><img :src="`${$awsSmallImages}/${user.image}`" alt="img" class="avatar avatar-md brround"></td>
										<td class="align-middle">{{user.userName}}</td>
										<td class="align-middle tableAbout">{{user.email}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<v-card-text v-else>Keine Benutzer gefunden</v-card-text>
					</div>
					<div class="card" v-if="checkBoxes.events">
						<v-card-title>Ereignisse</v-card-title>
						<div class="table-responsive table-hover" v-if="eventSearch.length > 0">
							<table class="table card-table table-vcenter text-nowrap">
								<thead>
									<th>Bild</th>
									<th>Name</th>
									<th>Datum</th>
								</thead>
								<tbody>
									<tr class="clickableTable" v-for="event in eventSearch" :key="event._id" @click="openSearchResult('Events', {event: event})">
										<td class="align-middle"><img :src="`${$awsSmallImages}/${event.image}`" alt="img" class="avatar avatar-md brround"></td>
										<td class="align-middle tableAbout">{{event.title}}</td>
										<td class="align-middle">{{$formatDate(event.eventDate)}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<v-card-text v-else>Keine Ereignisse gefunden</v-card-text>
					</div>
					<div class="card" v-if="checkBoxes.trips">
						<v-card-title>Trips</v-card-title>
						<div class="table-responsive table-hover" v-if="tripSearch.length > 0">
							<table class="table card-table table-vcenter text-nowrap">
								<thead>
									<th>Bild</th>
									<th>Name</th>
									<th>Datum</th>
								</thead>
								<tbody>
									<tr class="clickableTable" v-for="trip in tripSearch" :key="trip._id" @click="openSearchResult('Trips', {trip: trip})">
										<td class="align-middle"><img :src="`${$awsSmallImages}/${trip.image}`" alt="img" class="avatar avatar-md brround"></td>
										<td class="align-middle tableAbout">{{trip.title}}</td>
										<td class="align-middle">{{$formatDate(trip.tripDate)}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<v-card-text v-else>Keine Trips gefunden</v-card-text>
					</div>
					<div class="card" v-if="checkBoxes.posts">
						<v-card-title>Beiträge</v-card-title>
						<div class="table-responsive table-hover" v-if="postSearch.length > 0">
							<table  class="table card-table table-vcenter text-nowrap">
								<thead>
									<th>Bild</th>
									<th>Benutzername</th>
									<th>Beschreibung</th>
								</thead>
								<tbody>
									<tr class="clickableTable" v-for="post in postSearch" :key="post._id" @click="openSearchResult('Posts', {post: post})">
										<td class="align-middle"><img :src="`${$awsSmallImages}/${post.galleryImages[0].url}`" alt="img" class="avatar avatar-md brround"></td>
										<td class="align-middle">{{post.user.userName}}</td>
										<td class="align-middle tableAbout">{{post.description}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<v-card-text v-else>Keine Beiträge gefunden</v-card-text>
					</div>
					<div class="card" v-if="checkBoxes.rewards">
						<v-card-title>Belohnungen</v-card-title>
						<div class="table-responsive table-hover" v-if="rewardSearch.length > 0">
							<table class="table card-table table-vcenter text-nowrap">
								<thead>
									<th>Bild</th>
									<th>Titel</th>
									<th>Beschreibung</th>
									<th>Typ</th>
								</thead>
								<tbody>
									<tr class="clickableTable" v-for="reward in rewardSearch" :key="reward._id" @click="openSearchResult('Rewards', {reward: reward})">
										<td class="align-middle"><img :src="`${$awsSmallImages}/${reward.image}`" alt="img" class="avatar avatar-md brround"></td>
										<td class="align-middle">{{reward.title}}</td>
										<td class="align-middle tableAbout">{{reward.category}}</td>
										<td class="align-middle">
											<div v-if="reward.type=='address'"> Addresse</div>
											<div v-if="reward.type=='email'"> Email</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<v-card-text v-else>Keine Belohnungen gefunden</v-card-text>
					</div>
				</v-card>
				<v-overlay :value="overlay" :opacity="0.5"></v-overlay>

	<!-- Router View -->
				<router-view></router-view>
			</div>
		</div>
		<!-- End app-content-->


		<!--Footer-->
		<!-- <footer class="footer">
			<div class="container">
				<div class="row align-items-center flex-row-reverse">
					<div class="col-md-12 col-sm-12 text-center">
						Copyright © 2021 <a href="javascript:void(0);">azea</a>. Designed with <span class="fa fa-heart text-danger"></span> by <a href="javascript:void(0);"> Spruko </a> All rights reserved
					</div>
				</div>
			</div>
		</footer> -->
		<!-- End Footer-->
	</div>

	
    </v-app>

	
		
	
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      drawer:null,
      footdrawer:true,
      selectedlanguage:null,
      possiblelanguages: [],
      standardRoutes: [
        {
          key: "objects",
          name: this.$t("objects"),
          icon: "fas fa-bomb",
          to: "/findings",
        },
        {
          key: "masterdata",
          name: this.$t("masterdata"),
          icon: "fas fa-database",
          to: "/masterdata",
          right: "WriteMasterdata",
        },
        {
          key: "users",
          name: this.$t("users"),
          icon: "fas fa-user",
          to: "/users",
          right: "ReadUsers",
        },
        {
          key: "roles",
          name: this.$t("roles"),
          icon: "fas fa-user-tag",
          to: "/roles",
          right: "WriteUsers",
        },
        {
          key: "customers",
          name: this.$t("customers"),
          icon: "fas fa-user-tie",
          to: "/customers",
          right: "GlobalAccess",
        },
      ],
      loginRoute: {
        key: "login",
        name: this.$t("login"),
        icon: "fas fa-user",
        to: "/login",
      },
      logoutRoute: {
        key: "logout",
        name: this.$t("logout"),
        icon: "fas fa-sign-out-alt",
      },
      allowedRoutes: [],
      user: null,
      language: {
        language: null,
        flag: null,
        shortForm: null
      },
      languages: [],
	  search: '',
	  megaMenu: false,
	  userSearch: [],
	  eventSearch: [],
	  tripSearch: [],
	  postSearch: [],
	  rewardSearch: [],
	  levelSearch: [],
	  postSearch: [],
	  overlay: false,
	  checkBoxes: {
		  user: true,
		  events: true,
		  trips: true,
		  posts: true,
		  rewards: true
	  }
    };
  },

  mounted() {
	if(!window.location.href.includes("/app")) {
		this.getCurrentUser();
	}
  },

  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },

  methods: {
	log(){
		console.log('con');
	},
    getCurrentUser() {
		var _this = this;
     	this.$axios.get("/user/profile/my/0/5").then(function(response){
			const user = response.data.data;
			Vue.prototype.$user = user;
			_this.$forceUpdate();
		})
		.catch(function(error){
			console.error(error);
		});
    },
    
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error(err.response.data.message);
      } else {
        console.error(err);
      }
    },

    logout() {
      this.$axios
        .get("/signOut")
        .then(this.logoutDone)
        .catch(this.logoutFailed);
    },
    logoutDone(response) {
      this.switchToLoginPage();
    },
    logoutFailed(error) {
      this.switchToLoginPage();
    },
    switchToLoginPage() {
	  this.$router.push("login");
      Vue.prototype.$user = null;
      localStorage.setItem("id_token", "");
    },
    openFullscreen(){
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else {
			if (document.exitFullscreen) {
			document.exitFullscreen();
			}
		}
	},
	globalSearch() {
		let _this = this;

		// Search for Users
		if(this.checkBoxes.user) {
			this.$axios.get('/user/search/' + this.search +'/0/5/-1').then(function(response){
				_this.userSearch = response.data.data;
			}).catch(function(error){
				console.error(error);
			});
		}

		// Search for Events
		if(this.checkBoxes.events){
			this.$axios.get("/event/search/" + this.search + "/0/5/-1")
			.then(function (response) {
			  _this.eventSearch = response.data.data;
			})
			.catch(function (error) {
			  console.error(error);
			});
		}

		// Search for Trips
		if(this.checkBoxes.trips){
			this.$axios.get("/trip/search/" + this.search + "/0/5/-1")
			.then(function (response) {
			  _this.tripSearch = response.data.data;
			})
			.catch(function (error) {
			  console.error(error);
			});
		}

		// Search for Rewards
		if(this.checkBoxes.rewards){
			this.$axios.get("/reward/search/" + this.search + "/0/5")
			.then(function (response) {
			  _this.rewardSearch = response.data.data;
			})
			.catch(function (error) {
			  console.error(error);
			});
		}

		// Search for Posts
		if(this.checkBoxes.posts){
			this.$axios.get("/post/search/" + this.search + "/0/5/-1")
			.then(function (response) {
			  _this.postSearch = response.data.data;
			})
			.catch(function (error) {
			  console.error(error);
			});
		}

		
		document.addEventListener('keyup', function (event) {
			if(event.code == 'Escape') { 
				_this.closeSearchMenu();
			}
		})
	},

	openSearchResult(name, params) {
		if(this.$route.name == name) {
			if(name == 'Users'){
				this.$root.$emit('Users', params.user);
			} else if(name == 'Events') {
				this.$root.$emit('Events', params.event);
			} else if(name == 'Trips') {
				this.$root.$emit('Trips', params.trip);
			} else if(name == 'Posts') {
				this.$root.$emit('Posts', params.post);
			} else if(name == 'Rewards') {
				this.$root.$emit('Rewards', params.reward);
			}
			this.closeSearchMenu();
		} else {
			this.$router.push({name: name, params: params});
		}
	},

	openSearchMenu() {
		document.getElementById('megaMenuCard').setAttribute('style', "visibility: visible");
		this.overlay = true;
	},
	closeSearchMenu() {
		document.getElementById('megaMenuCard').setAttribute('style', "visibility: hidden");
			this.search = "";
			this.overlay = false;
	}

  },
  watch: {
    $route(to, from) {
      if (from.path === "/login" && to.path !== "/reset-password") {
        this.getCurrentUser();
      }
	  this.closeSearchMenu();
    },
	search: function () {
      if (this.search == "") {
        this.closeSearchMenu();
      } else {
		  if(this.search.length > 1) {
			this.openSearchMenu();
			this.globalSearch();
		}
      }
    },
	checkBoxes: {
		handler() {
			this.globalSearch();
		},
		deep: true,
	},
  },
};
</script>
