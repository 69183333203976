<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Ereignisse</h4>
        </div>
      </div>
      <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditEvent(editEvent, 'new')"
          >Event hinzufügen</a
        >
        <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
        <div class="dropdown-menu border-0">
                <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                <a class="dropdown-item" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                <a class="dropdown-item active" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
        </div>
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
          aria-controls="example1"
        />
        <br />
      </div>
      <div>
        <!-- Left Area -->
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Bild</th>
                  <th>Name</th>
                  <th>Datum</th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="event in events" :key="event._id">
                  <td class="align-middle">
                    <img
                      :src="`${$awsLargeImages}/${event.image}`"
                      alt="img"
                      class="avatar avatar-md brround"
                    />
                  </td>
                  <td class="align-middle">{{ event.title }}</td>
                  <td class="align-middle">
                    {{ $formatDate(event.eventDate) }}
                  </td>
                  <td>
                    <a
                      title="Ereignis bearbeiten"
                      class="btn btn-primary btn-small mr-1"
                      @click="openEditEvent(event, 'edit')"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a
                      title="Ereignis löschen"
                      class="btn btn-small btn-danger"
                      @click="openDeleteEvent(event)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      </div>
    </div>

    <v-dialog v-model="editDialog" width="500">
      <v-card>
          <v-card-title class="card">
            <h4 class="page-title text-primary">Ereigniss Bearbeiten</h4>
          </v-card-title>
        <v-card-text>

          <div class="form-group">
            <label class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="editEvent.title"
              v-bind:class="{'is-invalid': emptyValidation && (editEvent.title == null || editEvent.title == '')}"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Beschreibung</label>
            <textarea
              rows="4"
              class="form-control"
              placeholder="Beschreibung"
              v-model="editEvent.description"
              v-bind:class="{'is-invalid': emptyValidation && (editEvent.description == null || editEvent.description == '')}"
            ></textarea>
          </div>

          <div class="form-group">
            <label class="form-label">Datum</label>
            <input
              type="date"
              class="form-control"
              placeholder=""
              v-model="editEvent.eventDateObject"
              v-bind:class="{'is-invalid': emptyValidation && (editEvent.eventDateObject == null || editEvent.eventDateObject == '')}"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Standort</label>
            <GoogleMap v-model="editEvent.eventAddressObject" :validated="emptyValidation && editEvent.eventAddressObject ? true : false"/>
            
          </div>

          <div class="form-group">
            <label class="form-label">Bild</label>
                <div class="card overflow-hidden shadow-none border" v-if="editEvent.editType == 'edit'">
                  <img :src="`${$awsLargeImages}/${editEvent.image}`" alt="image" class="card-image1 ">
                </div>
                <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="input-group file-browser mb-5 mt-4">
                  <input
                    type="text"
                    id="imageFileDisplay"
                    class="form-control browse-file"
                    placeholder=""
                    disabled
                    v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editEvent.image == null || editEvent.image == '')}"
                  />
                  <label class="input-group-text btn btn-primary">
                    Browse
                    <input
                      id="imageFile"
                      type="file"
                      class="file-browserinput"
                      style="display: none"
                      multiple=""
                      accept="image/png, image/jpeg"
                      v-on:change="setFileName"
                    />
                  </label>
                </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
          <a class="btn btn-primary btn-pill" @click="saveEvent()">Speichern</a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
        v-model="deleteDialog"
        width="500">
        <v-card>
         <v-card-title class="card">
            <h4 class="page-title text-primary">Ereigniss löschen</h4>
          </v-card-title>
          <v-card-text>
            Sind sie sicher, dass sie dieses Ereigniss löschen wollen?
          </v-card-text>
                    <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteEvent()">Löschen</a>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GoogleMap from "./widget/GoogleMap.vue";

export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,
      search: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      tenants: [
        {
          name: "",
        },
      ],

      // Personal Data
      me: null,

      events: [],
      editEvent: {},
      updatedPic: '',

      emptyValidation: false,

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      currentTimeSpan: 'AllTime'
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchEvents();
      } else {
        this.page = 0;
        console.log(this.search);
        this.searchEvent();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchEvents();
    if(this.$route.params.event) {
      this.openEditEvent(this.$route.params.event, 'edit');
    }
    this.$root.$on('Events', (event) => {
      this.openEditEvent(event, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastEvents == this.ElementPerPage || this.lastEvents == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/trip/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
                          .then(function (response){
                            _this.lastEvents = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.events.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/trip/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan()).then(function(response){
                      _this.lastEvents = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.events.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchEvents() {
      var _this = this;
      this.$axios
        .get("/event/all/0/" + this.ElementPerPage + '/' + this.getCurrentTimeSpan())
        .then(function (response) {
          _this.events = response.data.data;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchEvent() {
      var _this = this;
      this.$axios
        .get(
          "/event/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
            + '/' + this.getCurrentTimeSpan()
        )
        .then(function (response) {
          _this.events = response.data.data;
          _this.lastEvents = _this.events.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openEditEvent(event, type) {
      this.initializeEvent();
      this.updatedPic = '';
      if(type == "edit") {
        this.editEvent = event;
        this.editEvent.eventDateObject = event.eventDate.substring(0, event.eventDate.indexOf("T"));
        // this.editEvent.eventAddressObject = this.editEvent.eventAddress;
        const eventAddressVar = this.editEvent.eventAddress;
        this.editEvent.eventAddressObject = eventAddressVar;
      }
      this.editEvent.editType = type;
      console.log(event);
      console.log(this.editEvent);
      
      
      this.editDialog = true;
    },
    openDeleteEvent(event) {
      this.deleteDialog = true;
      this.editEvent = event;
    },
    deleteEvent() {
      let _this = this;
      this.$axios
        .delete('/event/delete/' + this.editEvent._id)
        .then(function (response) {
          console.log(response.data);
            _this.deleteDialog = false;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    saveEvent() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        if(document.getElementsByClassName('is-invalid').length > 0) {
          this.emptyDialog = true;
          return;
        }
        _this.saveEventToServer();
      });
    },
    saveEventToServer() {
      let _this = this;
      const eventToSave = Object.assign({}, this.editEvent);
      eventToSave.eventDate = eventToSave.eventDateObject;
      if(this.editEvent.editType == 'new') {
        eventToSave.eventAddress.city = eventToSave.eventAddressObject.city;
        eventToSave.eventAddress.place = eventToSave.eventAddressObject.place;
        eventToSave.eventAddress.country = eventToSave.eventAddressObject.country;
        eventToSave.eventAddress.state = eventToSave.eventAddressObject.state;
        eventToSave.eventAddress.location.type = eventToSave.eventAddressObject.type;
        eventToSave.eventAddress.location.coordinates = eventToSave.eventAddressObject.coordinates;
      }
      

      delete eventToSave.eventAddressObject;
      delete eventToSave.eventDateObject;
      delete eventToSave.editType;
      if(this.editEvent.editType == 'edit') {
        eventToSave.eventId = eventToSave._id;
      }
      var fileToUpload = null;
      if(document.getElementById('imageFile').files.length > 0 ) {
        if(((document.getElementById('imageFile').files[0].size/1024)/1024).toFixed(4) > 20) {
          this.$showErrorFileSize(20);
          return;
        }
        fileToUpload = document.getElementById('imageFile').files[0];

        delete eventToSave.image;
      }
      delete eventToSave._id;

      const body = this.$formatUrlEncoded(eventToSave, fileToUpload);
      if(this.editEvent.editType == 'new') {
        this.$axios.post('/event/add', body).then(function(response){
            console.log(response.data);
            _this.editDialog = false;
            _this.fetchEvents();
          }).catch(function(error){
            console.error(error);
          });
      } else {
        this.$axios.put('/event/update', body).then(function(response){
            _this.editDialog = false;
            _this.fetchEvents();
          }).catch(function(error){
            console.error(error);
          });
      }
    },
    initializeEvent() {
      this.editEvent = {
        _id: "",
        description: "",
        eventAddressObject: {},
        eventAddress: {
          city: "",
          country: "",
          place: "",
          state: "",
          location: {
            type: "",
            coordinates: "",
          },
        },
        eventDate: "",
        image: "",
        title: "",
        eventDateObject: null,
        editType: "",
      };
      this.updatedPic = '';
    },
    checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    },
    setFileName() {
       let path = document.getElementById('imageFile').value;
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
  },
  setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchEvents();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    }
  },

  name: "App",
  components: {
    GoogleMap,
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

