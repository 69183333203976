<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Marken</h4>
        </div>
      </div>
      <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('Models')"
          >zurück</a
        >
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
          aria-controls="example1"
        />
        <br />
      </div>
      <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                      <th>Hersteller</th>
                  </thead>
                  <tbody>
                    <tr v-for="make in makes" :key="make._id">
                    <td class="align-middle">{{ make }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>

      </div>
      <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect="infiniteScrolling"></div>
    </div>

  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      makes: [],
      editMake: {},


      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchModels();
      } else {
        this.page = 0;
        this.searchMakes();
      }
    },
  },

  mounted() {
    this.fetchMakes();
    
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastCars == this.ElementPerPage || this.lastCars == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/make/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage)
                          .then(function (response){
                            _this.lastCars = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.makes.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/make/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage).then(function(response){
                      _this.lastCars = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.makes.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchMakes() {
      var _this = this;
      this.$axios
        .get("/make/0/" + this.ElementPerPage)
        .then(function (response) {
          _this.makes = response.data.data;
          _this.lastMakes = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchMakes() {
      let _this = this;
      this.$axios.get('/make/search/' + this.editModel.make + '/0/5').then(function (response) {
        _this.makes = response.data.data;
      }).catch(function (error) {
        console.error(error);
      })
    },

   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>