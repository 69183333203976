<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Erlaubte Versionen konfigurieren</h4>
        </div>
      </div>
    </div>
    <div>
      <div class="card p-5">
          <div class="form-group">
              <label class="form-label">Update erforderlich ab:</label>
              <input class="form-control" type="text" v-model="versions.minimum" v-bind:class="{'is-invalid': emptyValidation && (versions.minimum == null || versions.minimum == '')}">
          </div>
          <div class="form-group">
              <label class="form-label">Update empfehlen ab:</label>
              <input class="form-control" type="text" v-model="versions.recommend">
          </div>
          <div class="form-group">
            <a class="btn btn-primary" @click="submitUpdate()">Updaten</a>
          </div>
      </div>
    </div>

    <v-dialog v-model="confirmDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Bestätigen</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Möchten sie diese Änderung übernehmen?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="sendUpdate()">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Absenden muss zumindest die niedrigste mögliche Version ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,

      versions: {
          minimum: "",
          recommend: "",
      },
      emptyDialog: false,
      confirmDialog: false,
      emptyValidation: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    this.getVersions();
  },

  methods: {
    getVersions() {
        const _this = this;
        this.$axios.get('/admin/appversions').then((response) => {
            _this.versions.minimum = response.data.data.minimumVersion;
            _this.versions.recommend = response.data.data.recommendUpdate;
            console.log(_this.versions);
        }).catch((error) => console.error(error));
    },
    submitUpdate() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();
      Vue.nextTick(() => {
            if(document.getElementsByClassName('is-invalid').length > 0) {
              this.emptyDialog = true;
              return;
            }
            this.confirmDialog = true;
      });  
    },
    sendUpdate() {
        let _this = this;
        const body = _this.versions;
        console.log(body);
        this.$axios.post('/admin/appversions/update', body).then(function(response) {
            _this.confirmDialog = false;
            _this.emptyValidation = false;
        }).catch(function(error){
            console.error(error);
        })
    }
   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>