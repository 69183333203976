<script>
/**
 * @author CodeGuides GmbH, alle Rechte vorbehalten
 */
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

Chart.defaults.global.defaultFontColor = 'rgba(223, 217, 245, 0.8)';
Chart.defaults.global.responsive = true;
Chart.defaults.global.maintainAspectRatio = false;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods:{
    updateChart(){
      this.renderChart(this.chartData, this.options)
    }
  }
}


</script>

<style>

</style>
