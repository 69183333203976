<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Neuigkeiten</h4>
        </div>
      </div>
      <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditNewsMessage(editNewsMessage, 'new')"
          >Neuigkeit hinzufügen</a
        >
      </div>
    </div>
    <div>
      <div>
        <!-- Left Area -->
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>Bild</th>
                  <th>Sprache</th>
                  <th>Beschreibung</th>
                  <th>Link</th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="newsMessage in newsMessages" :key="newsMessage._id">
                  <td class="align-middle">
                    <img
                      :src="`${$awsLargeImages}/${newsMessage.image}`"
                      alt="img"
                      class="avatar avatar-md brround"
                    />
                  </td>
                  <td class="align-middle"><img height="20" :src="`/images/flags/${newsMessage.language == 'en' ? 'us' : newsMessage.language}.svg`"></td>
                  <td class="align-middle" style="max-width: 250px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"><span>{{ newsMessage.description }}</span></td>
                  <td class="align-middle">{{ newsMessage.link }}</td>
                  <td>
                    <a
                      title="Neuigkeit bearbeiten"
                      class="btn btn-primary btn-small mr-1"
                      @click="openEditNewsMessage(newsMessage, 'edit')"
                      ><i class="fas fa-edit"></i
                    ></a>
                    <a
                      title="Neuigkeit löschen"
                      class="btn btn-small btn-danger"
                      @click="openDeleteNewsMessage(newsMessage)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="editDialog" width="500">
      <v-card>
          <v-card-title class="card">
            <h4 v-show="editNewsMessage._id == ''" class="page-title text-primary">Neuigkeit erstellen</h4>
            <h4 v-show="editNewsMessage._id != ''" class="page-title text-primary">Neuigkeit bearbeiten</h4>
          </v-card-title>
        <v-card-text>
          <div class="form-group">
            <label class="form-label">Beschreibung</label>
            <textarea
              rows="4"
              class="form-control"
              placeholder="Beschreibung"
              v-model="editNewsMessage.description"
              v-bind:class="{'is-invalid': emptyValidation && (editNewsMessage.description == null || editNewsMessage.description == '')}"
            ></textarea>
          </div>

          <div class="form-group">
            <label class="form-label">Link (optional)</label>
            <input
              type="text"
              v-model="editNewsMessage.link"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Sprache</label>
            <v-autocomplete
              :items="['de', 'en', 'pl', 'it', 'fr', 'dk', 'es']"
              color="white"
              v-model="editNewsMessage.language"
            > 
            <template v-slot:selection="{item}">
              <img height="20" :src="`/images/flags/${item == 'en' ? 'us' : item}.svg`">&nbsp;{{item}}
            </template>
            <template v-slot:item="{item}">
              <img height="20" :src="`/images/flags/${item == 'en' ? 'us' : item}.svg`">&nbsp;{{item}}
            </template>
            </v-autocomplete>
          </div>

          <div class="form-group">
            <label class="form-label">Link-Button (optional)</label>
            <input
              type="text"
              v-model="editNewsMessage.linkText"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label class="form-label">Bild</label>
                <div class="card overflow-hidden shadow-none border" v-if="editNewsMessage.editType == 'edit'">
                  <img :src="`${$awsLargeImages}/${editNewsMessage.image}`" alt="image" class="card-image1 ">
                </div>
                <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="input-group file-browser mb-5 mt-4">
                  <input
                    type="text"
                    id="imageFileDisplay"
                    class="form-control browse-file"
                    placeholder=""
                    disabled
                    v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editNewsMessage.image == null || editNewsMessage.image == '')}"
                  />
                  <label class="input-group-text btn btn-primary">
                    Browse
                    <input
                      id="imageFile"
                      type="file"
                      class="file-browserinput"
                      style="display: none"
                      multiple=""
                      accept="image/png, image/jpeg"
                      v-on:change="setFileName"
                    />
                  </label>
                </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
          <a class="btn btn-primary btn-pill" @click="saveNewsMessage()">Speichern</a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
        v-model="deleteDialog"
        width="500">
        <v-card>
         <v-card-title class="card">
            <h4 class="page-title text-primary">Neuigkeit löschen</h4>
          </v-card-title>
          <v-card-text>
            Sind sie sicher, dass sie diese Neuigkeit löschen wollen? Sie wird dann nicht mehr ausgespielt.
          </v-card-text>
                    <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="deleteDialog=false">Abbrechen</a>
              <a class="btn btn-danger btn-pill" @click="deleteNewsMessage()">Löschen</a>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,
      search: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      tenants: [
        {
          name: "",
        },
      ],

      // Personal Data
      me: null,

      newsMessages: [],
      editNewsMessage: {},
      updatedPic: '',

      emptyValidation: false,

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      currentTimeSpan: 'AllTime'
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchNewsMessages();
    if(this.$route.params.newsMessage) {
      this.openEditNewsMessage(this.$route.params.newsMessage, 'edit');
    }
    this.$root.$on('NewsMessages', (newsMessage) => {
      this.openEditNewsMessage(newsMessage, 'edit');
    })
  },

  methods: {
    fetchNewsMessages() {
      var _this = this;
      this.$axios
        .get("/newsmessage/all")
        .then(function (response) {
          _this.newsMessages = response.data.data;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.console.log(err.response.data);
      } else {
        console.error(err);
      }
    },
    openEditNewsMessage(newsMessage, type) {
      this.initializeNewsMessage();
      this.updatedPic = '';
      if(type == "edit") {
        this.editNewsMessage = newsMessage;
      }
      if(type == "new"){
        this.editNewsMessage.language = "de";
      }
      this.editNewsMessage.editType = type;
      console.log(newsMessage);
      console.log(this.editNewsMessage);
      
      
      this.editDialog = true;
    },
    openDeleteNewsMessage(newsMessage) {
      this.deleteDialog = true;
      this.editNewsMessage = newsMessage;
    },
    deleteNewsMessage() {
      let _this = this;
      this.$axios
        .delete('/newsmessage/delete/' + this.editNewsMessage._id)
        .then(function (response) {
          console.log(response.data);
          _this.deleteDialog = false;
          _this.fetchNewsMessages();
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    saveNewsMessage() {
      let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        if(document.getElementsByClassName('is-invalid').length > 0) {
          this.emptyDialog = true;
          return;
        }
        _this.saveNewsMessageToServer();
      });
    },
    saveNewsMessageToServer() {
      let _this = this;
      const newsMessageToSave = Object.assign({}, this.editNewsMessage);

      var fileToUpload = null;
      if(document.getElementById('imageFile').files.length > 0 ) {
        if(((document.getElementById('imageFile').files[0].size/1024)/1024).toFixed(4) > 20) {
          this.$showErrorFileSize(20);
          return;
        }
        fileToUpload = document.getElementById('imageFile').files[0];

        delete newsMessageToSave.image;
      }
      delete newsMessageToSave._id;

      const body = this.$formatUrlEncoded(newsMessageToSave, [fileToUpload]);
      if(this.editNewsMessage.editType == 'new') {
        this.$axios.post('/newsmessage', body).then(function(response){
            console.log(response.data);
            _this.editDialog = false;
            _this.fetchNewsMessages();
          }).catch(function(error){
            console.error(error);
          });
      } else {
        this.$axios.put('/newsmessage/' + this.editNewsMessage._id, body).then(function(response){
            _this.editDialog = false;
            _this.fetchNewsMessages();
          }).catch(function(error){
            console.error(error);
          });
      }
    },
    initializeNewsMessage() {
      this.editNewsMessage = {
        _id: "",
        description: "",
        image: "",
        link: "",
      };
      this.updatedPic = '';
    },
    checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    },
    setFileName() {
       let path = document.getElementById('imageFile').value;
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
  },
  setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchNewsMessages();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    }
  },

  name: "App",
  components: {
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}

.v-select__selections{
  color: white !important;
}
</style>

