<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Level</h4>
        </div>
      </div>
      <div class="page-rightheader">
          <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditLevel(editLevel, 'new')"
          >Level hinzufügen</a
        >
      </div>
    </div>
    <div>
      <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                      <th>Level</th>
                      <th>Coins</th>
                      <th>Bild</th>
                      <th>Nutzer</th>
                      <th>Aktion</th>
                  </thead>
                  <tbody>
                    <tr v-for="level in levels" :key="level._id">
                    <td class="align-middle">{{ level.level }}</td>
                    <td class="align-middle">{{ level.coins }}</td>
                    <td>
                        <img :src="`${$awsLargeImages}/${level.image}`" alt="img" class="avatar avatar-md brround ">
                    </td>
                    <td class="align-middle">{{level.amountOfUsers}}</td>
                    <td>
                      <a
                        title="Level bearbeiten"
                        class="btn btn-primary btn-small mr-1"
                        @click="openEditLevel(level, 'edit')"
                        ><i class="fas fa-edit"></i></a>
                        <a
                        title="Level löschen"
                        class="btn btn-small btn-danger"
                        @click="openDeleteLevel(level)"
                        ><i class="fas fa-trash-alt"></i
                      ></a>
                    </td>
                      </tr>
                  </tbody>
              </table>
              <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
          </div>

      </div>
    </div>
    <v-dialog
        v-model="editDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="card">
            <h4 class="page-title text-primary" v-if="editLevel.editType == 'new'">Level erstellen</h4>
            <h4 class="page-title text-primary" v-if="editLevel.editType == 'edit'">Level erstellen</h4>
          </v-card-title>
          <v-card-text>
          
          <div class="form-group"> 
            <label class="form-label">Level</label>
            <input type="number" class="form-control" placeholder="0" v-model="editLevel.level" v-bind:class="{'is-invalid': emptyValidation && (editLevel.level == null || editLevel.level == '')}">
          </div>
          <div class="form-group"> 
            <label class="form-label">Coins</label>
            <input type="number" class="form-control" placeholder="0" v-model="editLevel.coins" v-bind:class="{'is-invalid': emptyValidation && (editLevel.coins == null || editLevel.coins == '')}">
          </div>
          <div class="input-group file-browser mb-5 mt-4">
            <div class="card overflow-hidden shadow-none border" v-if="this.editLevel.editType != 'new'">
                  <img
                    :src="`${$awsLargeImages}/${editLevel.image}`"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
            <input
              id="imageFileDisplay"
              type="text"
              class="form-control browse-file"
              placeholder="Beispiel.jpg"
              disabled
              v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editLevel.image == null || editLevel.image == '')}"
            />
            <label class="input-group-text btn btn-primary">
              Browse
              <input
                id="imageFile"
                type="file"
                class="file-browserinput"
                style="display: none"
                multiple=""
                accept="image/png, image/jpeg"
                v-on:change="setFileName"
              />
            </label>
          </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill mr-1" @click="editDialog=false">Abbrechen</a>
              <a class="btn btn-primary btn-pill" @click="saveLevel()">Speichern</a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-card-title class="card">
              <h4 class="page-title text-primary">Level löschen</h4>
            </v-card-title>
            <v-card-text>
              Sind sie sicher, dass sie dieses Level löschen wollen?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="deleteDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-danger btn-pill" @click="deleteLevel()"
                >Löschen</a
              >
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog v-model="emptyDialog" width="500">
      <v-card>
        <v-card-title class="card">
            <h4 class="page-title text-primary">Fehler</h4>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          Zum Speichern müssen alle erforderlichen Felder ausgefüllt sein.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <a class="btn btn-primary btn-pill" @click="emptyDialog=false">Okay</a>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      levels: [],
      editLevel: {},
      updatedPic: '',

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      emptyValidation: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchLevels();
    
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastLevels == this.ElementPerPage || this.lastLevels == null){
                  console.log(this.page);
                    this.page++;
                    this.$axios
                          .get('/level/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage)
                          .then(function (response){
                            _this.lastLevels = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.levels.push(item));
                            }
                          })
                          .catch(this.onError);
                }
            }, 500);
        }
    },
    fetchLevels() {
      var _this = this;
      this.$axios
        .get("/level/all/0/" + this.ElementPerPage)
        .then(function (response) {
          _this.levels = response.data.data;
          _this.lastLevels = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    saveLevel() {
        let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        _this.saveLevelToServer();
      });
    },
    saveLevelToServer() {
      if(document.getElementsByClassName('is-invalid').length > 0) {
        this.emptyDialog = true;
        return;
      }
      var _this = this;
      const levelToSave = Object.assign({}, this.editLevel);

        delete levelToSave.completedUser;
        delete levelToSave.status;
        delete levelToSave.editType;
        levelToSave.levelId = levelToSave._id;
        delete levelToSave._id;
        delete levelToSave.user;
        delete levelToSave.createdAt;
        delete levelToSave.updatedAt;
        delete levelToSave.__v;
      if(this.editLevel.editType == 'new') {
          delete levelToSave.levelId;
      }

      var fileToUpload = null;
    if(document.getElementById('imageFile').files.length > 0 ) {
      fileToUpload = document.getElementById('imageFile').files[0];
      delete levelToSave.image;
    }

      const body = this.$formatUrlEncoded(levelToSave, fileToUpload);
      if(this.editLevel.editType == 'new') {
          this.$axios
            .post(
              "/level/add/", body
            )
            .then(function (response) {
              _this.fetchLevels();
              _this.editDialog = false;
            })
            .catch(function (error) {
              console.error(error);
            });
      } else {
          this.$axios
            .put(
              "/level/update/", body
            )
            .then(function (response) {
              _this.fetchLevels();
              _this.editDialog = false;
            })
            .catch(function (error) {
              console.error(error);
            });
      }
    },
    deleteReward() {
     let _this = this;
     this.$axios.delete("/level/delete/" + this.editLevel._id).then(function (response) {
     console.log(response.data);
            _this.deleteDialog = false;
            _this.fetchRewards();
          }).catch(function(error){
            console.error(error);
          });
   },
    openEditLevel(level, type) {
        this.initializeLevel();
        if(type == 'edit') {
            this.editLevel = level;
        }
        this.editLevel.editType = type;
        console.log(this.editLevel);
        this.editDialog = true;
    },
    openDeleteLevel(level){
      this.editLevel = level;
      this.deleteDialog = true;
    },
    deleteLevel() {
      let _this = this;
      this.$axios
        .delete("/level/delete/" + this.editLevel._id)
        .then(function (response) {
          _this.deleteDialog = false;
          this.fetchTrip();
        })
        .catch(function (error) {
          console.error(error);
        });

    },
    initializeLevel() {
        this.editLevel = {
            level: '',
            coins: '',
            image: '',
            status: '',
            completedUser: [],
            _id: '',
            editType: '',
        }
        this.updatedPic = '';
    },
    setFileName() {
       let path = document.getElementById('imageFile').value;
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
    },
    checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    }

   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>