import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'

import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import HttpService from './Http.js'
import VueI18n from 'vue-i18n'
import messages from './assets/messages.json'
import VueNoty from 'vuejs-noty'
import 'vuetify/dist/vuetify.min.css'
import 'vue-cal/dist/vuecal.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuejs-noty/dist/vuejs-noty.css'
import { getAxiosParams, getAWSImageUrls } from './constants.js'

// WYSIWYG Editor
import { VueEditor } from "vue2-editor";

// Vue Components
import Login from './components/Login.vue'
import Dashboard from './components/Dashboard.vue'
import Users from './components/Users.vue'
import Events from './components/Events.vue'
import PredefinedTrips from './components/PredefinedTrips.vue'
import Trips from './components/Trips.vue'
import Reported from './components/Reported.vue'
import Rewards from './components/Rewards.vue'
import RewardHistory from './components/RewardHistory.vue'
import Levels from './components/Levels.vue'
import Models from './components/Models.vue'
import Manufacturers from './components/Manufacturers.vue'
import Broadcast from './components/Broadcast.vue'
import UserAutos from './components/UserAutos.vue'
import BlacklistedUser from './components/BlacklistedUsers.vue'
import Posts from './components/Posts.vue'
import NewsMessages from './components/NewsMessages.vue'
import ValidateEmail from './components/ValidateEmail.vue'
import AppVersions from './components/AppVersions.vue'

// Widgets
import Upload from "./components/widget/Upload.vue"
import DatePicker from "./components/widget/DatePicker.vue"

import * as VueGoogleMaps from 'vue2-google-maps'

// Main Page
import App from './views/App.vue'

Vue.config.productionTip = false;

// Global Access Variables 
Vue.prototype.$axios = axios.create(getAxiosParams());
  
Vue.prototype.$axios.interceptors.request.use(
function (config) {
    const token = localStorage.getItem("id_token");
    if (token) {
    config.headers.Authorization = token;
    }
    return config;
},
function (error) {
    return Promise.reject(error);
}
);


Vue.prototype.$user = null;
Vue.prototype.$awsSmallImages = getAWSImageUrls().smallImages;
Vue.prototype.$awsMediumImages = getAWSImageUrls().mediumImages;
Vue.prototype.$awsLargeImages = getAWSImageUrls().largeImages;

Vue.prototype.$mapsAPIKey = 'AIzaSyA49hyJ8uklbAuGVDMVZFrVbZrc3-DJTNQ';

Vue.prototype.$formatDate = function(dateStr){
    // Return formatted date
    const date = new Date(dateStr);
}

window.Vue = Vue;

// Use base Components
Vue.use(BootstrapVue)
Vue.use(Vuetify)
Vue.use(VueRouter)
Vue.use(VueEditor, /* { default global options} */)
Vue.component("vue-editor", VueEditor);

// Components
Vue.use(Login)

// Widgets
Vue.use(Upload)
Vue.component("Upload", Upload);
Vue.use(DatePicker)
Vue.component("DatePicker", DatePicker);
Vue.use(VueGoogleMaps, {
    load: {
      key: Vue.prototype.$mapsAPIKey,
      libraries: 'places',
    }
  });

// Language
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'de',
    messages,
});


// Vuetify
const vuetifyOpts = {
    theme: {
        themes: {
            light: {
                primary: "#0069AE",
                secondary: "#003A61",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
                grey: "#2f2f2f",
            }
        }
    }
};
const vuetify = new Vuetify(vuetifyOpts);


// Vue Router
const router = new VueRouter({
    routes: [
        {
            path: "/", component: Dashboard
        },
        { path: "/login", name: "Login", component: Login, props: route => ({ routingTarget: route.query.routingTarget }) },
        { path: "/dashboard", name: "Dashboard", component: Dashboard },
        { path: "/users", name: "Users", component: Users },
        { path: "/events", name: "Events", component: Events },
        { path: "/newsmessages", name: "NewsMessages", component: NewsMessages },
        { path: "/predefinedTrips", name: "PredefinedTrips", component: PredefinedTrips },
        { path: "/trips", name: "Trips", component: Trips },
        { path: "/reported", name: "Reported", component: Reported },
        { path: "/rewards", name: "Rewards", component: Rewards },
        { path: "/rewardHistory", name: "RewardHistory", component: RewardHistory },
        { path: "/levels", name: "Levels", component: Levels },
        { path: "/models", name: "Models", component: Models },
        { path: "/Manufacturers", name: "Manufacturers", component: Manufacturers },
        { path: "/broadcast", name: "Broadcast", component: Broadcast },
        { path: "/userAutos", name: "UserAutos", component: UserAutos },
        { path: "/blacklisted", name: "Blacklisted", component: BlacklistedUser },
        { path: "/posts", name: "Posts", component: Posts },
        { path: "/validateEmail/*/*/*", name: "ValidateEmail", component: ValidateEmail },
        { path: "/versions", name: "Versions", component: AppVersions }
    ],
    mode: "history"
});

Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
});


// Notifications
Vue.use(VueNoty, {
    timeout: 6000,
    progressBar: true,
    killer: true,
    layout: 'topRight'
});


// Global Methods
Vue.prototype.$formatDate = function(dateStr){
    return new Date(dateStr).toLocaleDateString(navigator.languages, { year: 'numeric', month: '2-digit', day: '2-digit' });
}

Vue.prototype.$formatUrlEncoded = function(json, appendFile = null){
    var formData = new FormData();
    for ( var key in json ) {
        let data = json[key];
        if(typeof data === 'object' || typeof data === 'array') data = JSON.stringify(data);
        formData.append(key, data);
    }

    if(appendFile != null){
        for (let i = 0; i < appendFile.length; i++) {
            const file = appendFile[i];
            formData.append('file',file);
        }
    }
    // Array.from(appendFile).forEach(file => {formData.append('files',file)})
    // if(appendFile) formData.append('file', appendFile);
    return formData;
}

Vue.prototype.$routerPush = function(path, right, elsePath = null){
    if(this.$rights.includes(right)){
        this.$router.push(path);
    }else if(elsePath != null){
        this.$router.push(elsePath);
    }
}

Vue.prototype.$routerBack = function(){
    this.$router.go(-1);
}

Vue.prototype.$showErrorMissing = function(field){
    const msg = "Das Feld " + field + " darf nicht leer sein.";
    this.$noty.error(msg);
}

Vue.prototype.$showErrorInvalid = function(field){
    const msg = "Das Feld " + field + " hat einen ungültigen Wert.";
    this.$noty.error(msg);
}

Vue.prototype.$showErrorFileSize = function(size){
    const msg = "Die Datei darf nicht größer als " + size + " MB sein.";
    this.$noty.error(msg);
}

Vue.prototype.$showCommonError = function(){
    const msg = "Etwas ist schief gelaufen";
    this.$noty.error(msg);
}

Vue.prototype.$showErrorWithMessage = function(error) {
    this.$noty.error(error.message);
}


new Vue({ 
    render: h => h(App),
    components: { App },
    i18n,
    router,
    vuetify,
    watch:{
        $route (to, from){
            window.scrollTo(0, 0);
        }
    },
    beforeCreate: function(){
        if(window.location.href.includes("?lang")){
            window.location.href = window.location.href.substring(0, window.location.href.indexOf("?"));
        }
        if(window.location.href.includes("%3Flang")){
            window.location.href = window.location.href.substring(0, window.location.href.indexOf("%3Flang"));
        }
    },
    beforeMount: function () {
        var _this = this;
        this.$axios.get("/user/profile/my/0/5").then(function(response){
            const user = response.data.data;
            Vue.prototype.$user = user;
            console.log("Current User", user);
        })
        .catch(function(error){
            console.log("Not logged in", error);
            _this.checkWindowLocationAndRouteToLogin();
            });
    },
    methods: {
        setUser(user) {
            this.user = user;
        },
        checkWindowLocationAndRouteToLogin(){
            var location = window.location.href;
            if(location.startsWith("http://") || location.startsWith("https://")) location = location.substring(location.indexOf("//") + 2);
            console.log(location);
            const allowedRoutes = ["/login", "/validateEmail", "/app"];
            const unallowedRoutes = [];
            var routeAllowed = false;

            // Check allowedRoutes
            for (let i = 0; i < allowedRoutes.length; i++) {
                const allowedRoute = allowedRoutes[i];
                if(location.includes(allowedRoute)){
                    routeAllowed = true;
                    break;
                }
            }
            // Check unallowedRoutes
            for (let i = 0; i < unallowedRoutes.length; i++) {
                const unallowedRoute = unallowedRoutes[i];
                if(location.includes(unallowedRoute)){
                    routeAllowed = false;
                    break;
                }
            }
            if(routeAllowed) return;

            const unallowedRoute = location.substring(location.indexOf("/"));

            console.log(unallowedRoute);
            this.$router.push("/login?routingTarget=" + unallowedRoute);
        },
    }
}).$mount("#app");
