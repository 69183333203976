<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <div class="row justify-content-center">
            <div class="col-md verifyContainer">
              <div class="text-center mb-5">
                <img src="/images/brand/logo.png" class="header-brand-img desktop-lgo" alt="Azea logo">
              </div>
              <div class="card">
                <div class="card-body">
                  
                  <div class="mt-5" v-if="success">
                    <p class="mb-2 carn1 font-weight-bold text-center">{{this.$t('CONGRATULATIONS')}}</p>
                    <br>
                    <br>
                    <p class="text-center">{{this.$t('VERIFY_SUCCESS')}}</p>
                  </div>
                  <div class="mt-5" v-else>
                    <p class="mb-2 carn1 font-weight-bold text-center">{{this.$t('EXCUSE')}}</p>
                    <br>
                    <br>
                    <p class="text-center justify-content">{{this.$t('VERIFY_FAILURE')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routingTarget: String,
  },

  data() {
    return {
      success: '',
    };
  },

  mounted() {
    this.setLoginBodySettings(true);
    const args = window.location.pathname.split('/');
    this.$axios.get(`/validateEmail/${args[2]}/${args[3]}/${args[4]}`).then((response) => this.displayResult(response.data))
  },

  methods: {
    displayResult(data) { 
        if(data.success) {
            this.success = true;
        } else {
            this.success = false;
        }
    },

    setLoginBodySettings(bool){
        document.getElementById("app").classList.remove("login");
    }
  },
};
</script>

<style>
</style>