<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Belohnungen</h4>
        </div>
      </div>
      <div class="page-rightheader">
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="openEditReward(editReward, 'new')"
          >Belohnung hinzufügen</a
        >
        <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('rewardHistory')"
          >Bestellungen</a
        >
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
        />
        <br />
      </div>
      <div>
        <div class="card">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <thead>
                        <th>Nr.</th>
                        <th>Bild</th>
                        <th>Titel</th>
                        <th>Kategorie</th>
                        <th>Typ</th>
                        <th>Menge</th>
                        <th>Restmenge</th>
                        <th>Coins</th>
                        <th>Aktion</th>
                    </thead>
                    <tbody>
                        <tr v-for="reward in rewards" :key="reward._id">
                        <td class="align-left" style="padding-left: 1em;">{{ reward.orderNum }}</td>
                        <td class="align-middle">
                            <img
                                  :src="`${$awsLargeImages}/${reward.image}`"
                                  alt="img"
                                  class="avatar avatar-md brround"
                              />
                        </td>
                      <td class="align-middle">{{ reward.title }}</td>
                      <td class="align-middle">{{reward.category}}</td>
                      <td class="align-middle">
                          <div v-if="reward.type=='address'"> Addresse</div>
                          <div v-if="reward.type=='email'"> Email</div>
                      </td>
                      <td class="align-middle">{{reward.quantity}}</td>
                      <td class="align-middle">{{reward.remainingAmount}} <v-icon class="text-warning" v-show="reward.remainingAmount <= 0" size="18">fas fa-exclamation-triangle</v-icon></td>
                      <td class="align-middle">{{reward.coins}}</td>
                      <td>
                        <a
                          title="Belohnung bearbeiten"
                          class="btn btn-primary btn-small mr-1"
                          @click="openEditReward(reward, 'edit')"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          title="Belohnung löschen"
                          class="btn btn-small btn-danger mr-1"
                          @click="openDeleteReward(reward)"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                        <a
                          class="btn btn-primary btn-small mr-1"
                          @click="changeOrderNum(reward, -1)"
                          ><i class="fas fa-arrow-up"></i
                        ></a>
                        <a
                          class="btn btn-primary btn-small"
                          @click="changeOrderNum(reward, 1)"
                          ><i class="fas fa-arrow-down"></i
                        ></a>
                      </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
        </div>
      </div>
    </div>

    <!-- V-Dialoge -->
    <v-dialog v-model="editDialog">
        <v-card>
            <v-card-title>
                <h4 class="page-title text-primary" v-if="this.editReward.editType == 'new'">Belohnung hinzufügen</h4>
                <h4 class="page-title text-primary" v-if="this.editReward.editType == 'edit'">Belohnung bearbeiten</h4>
            </v-card-title>
            <v-card-text>
                <div class="form-group">
                    <label class="form-label">Titel</label>
                    <input type="text" class="form-control" placeholder="Titel..." v-model="editReward.title" v-bind:class="{'is-invalid': emptyValidation && (editReward.title == null || editReward.title == '')}">
                </div>
                <div class="form-group">
                <label class="form-label">Typ</label>
                <select
                  class="form-control"
                  v-model="editReward.type"
                  v-bind:class="{'is-invalid': emptyValidation && (editReward.type == null || editReward.type == '')}"
                >
                  <option value="address">Addresse</option>
                  <option value="email">Email</option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-label">Kategorie</label>
                <select
                  class="form-control"
                  v-model="editReward.category"
                  v-bind:class="{'is-invalid': emptyValidation && (editReward.category == null || editReward.category == '')}"
                >
                  <option value="Merchandise">Merchandise</option>
                  <option value="Fahrzeuge">Fahrzeuge</option>
                  <option value="Putzmittel">Putzmittel</option>
                  <option value="Roller">Roller</option>
                  <option value="Tuning">Tuning</option>
                  <option value="Nur lokale Pramien">Nur lokale Prämien</option>
                </select>
              </div>
              <div class="form-group">
                  <label class="form-label">Coins</label>
                  <input type="number" class="form-control" placeholder="" v-model="editReward.coins" v-bind:class="{'is-invalid': emptyValidation && (editReward.coins == null || editReward.coins == '')}">
              </div>
              <div class="form-group">
                  <label class="form-label">Menge</label>
                  <input type="number" class="form-control" placeholder="" v-model="editReward.quantity" v-bind:class="{'is-invalid': emptyValidation && (editReward.quantity == null || editReward.quantity == '')}">
              </div>
              <div class="form-group">
                <label class="form-label">Bild</label>
                <div class="imgcontainer card overflow-hidden shadow-none border" v-if="this.editReward.editType == 'edit'">

                  <!-- <img :src="`${$awsLargeImages}/${editReward.image}`" alt="image" class="card-image1 "> -->

                  <v-carousel>
                    <v-carousel-item
                      v-for="(item,i) in editReward.images"
                      :key="i"
                      :src="`${$awsLargeImages}/${item.url}`"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                </div>
                <div class="card overflow-hidden shadow-none border" v-if="updatedPic">
                  <img
                    :src="updatedPic"
                    alt="image"
                    class="img-thumb"
                  />
                </div>
                <div class="input-group file-browser mb-5 mt-4">
                    <input
                      id="imageFileDisplay"
                      type="text"
                      class="form-control browse-file"
                      placeholder="Beispiel.jpg"
                      disabled
                      v-bind:class="{'is-invalid': emptyValidation && checkFileInput() && (editReward.image == null || editReward.image == '')}"
                    />
                    <label class="input-group-text btn btn-primary">
                      Browse
                      <input
                        id="imageFile"
                        type="file"
                        class="file-browserinput"
                        style="display: none"
                        multiple
                        accept="image/png, image/jpeg"
                        v-on:change="setFileName"
                      />
                    </label>
                  </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="editDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-primary btn-pill" @click="saveReward()"
                >Speichern</a
              >
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog">
          <v-card>
            <v-card-title class="card">
              <h4 class="page-title text-primary">Trip löschen</h4>
            </v-card-title>
            <v-card-text>
              Sind sie sicher, dass sie diesen Trip löschen wollen?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="deleteDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-danger btn-pill" @click="deleteReward()"
                >Löschen</a
              >
            </v-card-actions>
          </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 50,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      rewards: [],
      editReward: {},
      updatedPic: '',

      editDialog: false,
      deleteDialog: false,
      emptyDialog: false,
      emptyValidation: false,
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchRewards();
      } else {
        this.page = 0;
        this.searchReward();
      }
    },
    editDialog: function () {
       if(this.editDialog == false) {
         this.emptyValidation = false;
         this.$forceUpdate();
       }
    }
  },

  mounted() {
    this.fetchRewards();
    if(this.$route.params.reward){
      this.openEditReward(this.$route.params.reward, 'edit');
    }
    this.$root.$on('Rewards', (reward) => {
      this.openEditReward(reward, 'edit');
    })
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastRewards == this.ElementPerPage || this.lastRewards == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .get('/reward/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage)
                          .then(function (response){
                            _this.lastRewards = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.rewards.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/reward/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage).then(function(response){
                      _this.lastRewards = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.rewards.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchRewards() {
      var _this = this;
      this.$axios
        .get("/reward/all/0/" + this.ElementPerPage)
        .then(function (response) {
          _this.rewards = response.data.data;
          _this.lastRewards = response.data.data.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    searchReward() {
      var _this = this;
      this.$axios
        .get(
          "/reward/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage
        )
        .then(function (response) {
          _this.rewards = response.data.data;
          _this.lastRewards = _this.rewards.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
   openEditReward(reward, type) {
     this.initializeReward();
     if(type == 'edit') {
       this.editReward = reward;
       }
       this.editReward.editType = type;
       this.editDialog = true;
   },
   saveReward() {
     let _this = this;
      this.emptyValidation = true;
      this.$forceUpdate();


      Vue.nextTick(() => {
        _this.saveRewardToServer();
      });
   },
   changeOrderNum(reward, direction, wasAlreadyOtherReward = false){
     if(direction == null) {
       console.error("Fehler: Keine Richtung beim Wechseln der Reihenfolgenummer angegeben.");
       return;
     }
     var _this = this;
     const newOrderNum = reward.orderNum + direction;
     const body = {
       orderNum: newOrderNum,
       rewardId: reward._id
     }
     this.$axios.put("/reward/reorder", body).then(function (response) {
        console.log(response.data);
        _this.fetchRewards();
      }).catch(function(error){
        console.error(error);
      });

      if(!wasAlreadyOtherReward){
        // Commit change to other Reward to swap Order Numbers
        const otherReward = this.findRewardByOrderNum(newOrderNum);
        if(otherReward != null){
          this.changeOrderNum(otherReward, direction * -1, true);
        }
      }
   },
   saveRewardToServer() {
     let _this = this;
     if(document.getElementsByClassName('is-invalid').length > 0) {
        this.emptyDialog = true;
        return;
      }
     const rewardToSave = Object.assign({}, this.editReward);
     
     rewardToSave.rewardId = rewardToSave._id;

     delete rewardToSave._id;
     delete rewardToSave.userRedeemed;
     delete rewardToSave.editType;
     delete rewardToSave.redeemedUsers;
     delete rewardToSave.remainingAmount;

     if(this.editReward.editType == 'new') {
       delete rewardToSave.rewardId;
     }

     var fileToUpload = null;
      if(document.getElementById('imageFile') != null && document.getElementById('imageFile').files.length > 0 ) {
        if(((document.getElementById('imageFile').files[0].size/1024)/1024).toFixed(4) > 2) {
          this.$showErrorFileSize(2);
          return;
        }
        fileToUpload = document.getElementById('imageFile').files;
        delete rewardToSave.image;
      }

     const body = this.$formatUrlEncoded(rewardToSave, fileToUpload);
     console.log(body.getAll('files'));
     if(this.editReward.editType == 'new') {
       this.$axios.post("/reward/add", body).then(function (response) {
         console.log(response.data);
            _this.editDialog = false;
            _this.fetchRewards();
       }).catch(function(error){
            console.error(error);
          });
     } else {
       this.$axios.put("/reward/update", body).then(function (response) {
         console.log(response.data);
            _this.editDialog = false;
            _this.fetchRewards();
          }).catch(function(error){
            console.error(error);
          });
     }
   },
   openDeleteReward(reward) {
     this.editReward = reward;
     this.deleteDialog = true;
   },
   deleteReward() {
     let _this = this;
     this.$axios.delete("/reward/delete/" + this.editReward._id).then(function (response) {
     console.log(response.data);
            _this.deleteDialog = false;
            _this.fetchRewards();
          }).catch(function(error){
            console.error(error);
          });
   },
   initializeReward() {
       this.editReward = {
           _id : '',
           title: '',
           image: '',
           coins: '',
           category: '',
           type: '',
           quantity: '',
           userRedeemed: '',
           editType: '',
       }
       this.updatedPic = '';
   },
   checkFileInput() {
      if(document.getElementById('imageFile').files.length == 0) {
        return true;
      }
      return false;
    },
   setFileName() {
       let path = document.getElementById('imageFile').value;
       if(path) {
         var startIndex = path.lastIndexOf("\\");
         document.getElementById('imageFileDisplay').value = path.substring(startIndex + 1);
         this.updatedPic = URL.createObjectURL(document.getElementById('imageFile').files[0]);
       } else {
         document.getElementById('imageFileDisplay').value = '';
       }
    },

    findRewardByOrderNum(orderNum){
      for (let i = 0; i < this.rewards.length; i++) {
        const reward = this.rewards[i];
        if(reward.orderNum == orderNum) return reward;
      }
      return null;
    }

  },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>
