<template>
  <div>
    <div class="page-header">
      <div class="page-leftheader">
        <div class="headline">
          <h4 class="page-title text-primary">Alle Belohnungen</h4>
        </div>
      </div>
      <div class="page-rightheader">
          <a
          class="btn btn-primary btn-pill mr-1"
          @click="$router.push('rewards')"
          >Zurück</a
        >
        <a class="btn btn-primary btn-pill" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-calendar me-2 fs-14"></i> Nach Datum Filtern</a>
                    <div class="dropdown-menu border-0">
                            <a class="dropdown-item" id="dropdownToday" @click="setTimePicker('Today')">Letzte 24 Stunden</a>
                            <a class="dropdown-item" id="dropdownLastWeek" @click="setTimePicker('LastWeek')">Letzte 7 Tage</a>
                            <a class="dropdown-item" id="dropdownLastMonth" @click="setTimePicker('LastMonth')">Letzte 30 Tage</a>
                            <a class="dropdown-item active" id="dropdownAllTime" @click="setTimePicker('AllTime')">Gesamt</a>
                    </div>
      </div>
    </div>
    <div>
      <div>
        <input
          v-model="search"
          type="search"
          class="form-control form-control-sm"
          placeholder="Suche..."
          aria-controls="example1"
        />
        <br />
      </div>
      <v-tabs>
            <v-tab href="#tab-1" @click="setTableType(false)">
              <div class="page-leftheader">
                  <p class="text-primary">Nicht verschickt</p>
              </div>
            </v-tab>
            <v-tab href="#tab-2" @click="setTableType(true)">
              <div class="page-leftheader">
                  <p class="text-primary">Verschickt</p>
              </div>
            </v-tab>

      </v-tabs>
      <div class="card">
          <div class="table-responsive">
              <table class="table card-table table-vcenter">
                  <thead>
                      <th>Titel</th>
                      <th>Kategorie</th>
                      <th>Benutzername</th>
                      <th>Typ</th>
                      <th>Coins</th>
                      <th>Adresse</th>
                      <th>Email</th>
                      <th>Datum</th>
                      <th v-if="tableType.rewardSent">Sendungsnummer</th>
                      <th>Aktion</th>
                  </thead>
                  <tbody>
                    <tr v-for="rewardObject in rewards" :key="rewardObject._id">
                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.title }}</td>
                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.category }}</td>
                    <td class="align-middle">{{ rewardObject.userName == null ? '' : rewardObject.userName }}</td>
                    <td class="align-middle">
                        <div v-if="rewardObject.reward != null && rewardObject.reward.type=='address'"> Addresse</div>
                        <div v-if="rewardObject.reward != null && rewardObject.reward.type=='email'"> Email</div>
                    </td>
                    <td class="align-middle">{{ rewardObject.reward == null ? '' : rewardObject.reward.coins }}</td>
                    <td class="align-middle"> {{ rewardObject.address == null ? '' :rewardObject.address }} </td>
                    <td class="align-middle">{{ rewardObject.email != '' ? rewardObject.email : rewardObject.user ? rewardObject.user.email : '' }}</td>
                    <td class="align-middle">{{$formatDate(rewardObject.createdAt)}}</td>
                    <td v-if="!tableType.rewardSent">
                      <a
                        class="btn btn-primary btn-small mr-1 mb-1"
                        @click="copyToClipboard(rewardObject)"
                        ><i class="fa fa-copy"></i></a>
                      <a
                        class="btn btn-primary btn-small mr-1 mb-1"
                        @click="downloadPdf(rewardObject)"
                        ><i class="fas fa-file-pdf"></i></a>
                      <a
                        class="btn btn-primary btn-small mr-1 mb-1"
                        @click="sendReward(rewardObject)"
                        ><i class="fas fa-paper-plane"></i></a>
                    </td>
                    <td v-if="tableType.rewardSent" class="align-middle">{{ rewardObject.trackingNumber == null ? "" : rewardObject.trackingNumber }}</td>
                    <td v-if="tableType.rewardSent">
                      <a
                        class="btn btn-primary btn-small mr-1"
                        @click="openTrackingDialog(rewardObject)"
                        ><i class="fas fa-edit"></i></a>
                    </td>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
    </div>
    <v-dialog v-model="trackingDialog" width="500">
          <v-card>
            <v-card-title class="card">
              <h4 class="page-title text-primary">Sendungsnummer hinzufügen</h4>
            </v-card-title>
            <v-card-text>
            <div class="form-group">
                <label class="form-label">Sendungsnummer</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Sendungsnummer"
                  v-model="editOrder.trackingNumber"
                />
            </div>
            <div class="form-group">
                <label class="form-label">Transportunternehmen</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="editOrder.carrier"
                >
                  <option value="dhl" selected>DHL</option>
                  <option value="gls">GLS</option>
                </select>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <a
                class="btn btn-primary btn-pill mr-1"
                @click="trackingDialog = false"
                >Abbrechen</a
              >
              <a class="btn btn-danger btn-pill" @click="updateOrder(editOrder)"
                >Speichern</a
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>


export default {
  components: {},

  data() {
    return {
      page: 0,
      totalPages: 1,
      ElementPerPage: 25,
      sort: null,
      order: true,

      history: false,
      
      search: "",

      rewards: [],
      editOrder: {},

      tableType: {
          rewardSent: false
      },

      editDialog: false,
      trackingDialog: false,
      emptyDialog: false,
      currentTimeSpan: 'AllTime',
    };
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    search: function () {
      if (this.search == "") {
        this.fetchRewards();
      } else {
        this.page = 0;
        this.searchReward();
      }
    },
    tableType: {
      handler() {
        console.log(this.tableType);
      },
      deep: true,
    }
  },

  mounted() {
    this.fetchRewards();
    
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.lastRewards == this.ElementPerPage || this.lastRewards == null){
                  console.log(this.page);
                  if(this.search ==  "" || this.search == null){
                    this.page++;
                    this.$axios
                          .post('/reward/history/all/' + this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan(), this.tableType)
                          .then(function (response){
                            _this.lastRewards = response.data.data.length;
                            if(response.data.data.length >= 1) {
                              response.data.data.forEach(item => _this.rewards.push(item));
                            }
                          })
                          .catch(this.onError);
                  }
                  else{
                    console.log('infinitesearch');
                    this.page++;
                    this.$axios.get('/reward/history/search/' + this.search +'/'+ this.page * this.ElementPerPage + '/' + this.ElementPerPage + '/' + this.getCurrentTimeSpan(), this.tableType).then(function(response){
                      _this.lastRewards = response.data.data.length;
                      if(response.data.data.length >= 1) {
                        response.data.data.forEach(item => _this.rewards.push(item));
                      }
                    }).catch(function(error){
                      console.error(error);
                    });
                  }
                }
            }, 500);
        }
    },
    fetchRewards() {
      var _this = this;
      this.$axios
        .post("/reward/history/all/0/" + this.ElementPerPage + '/' + this.getCurrentTimeSpan(), this.tableType)
        .then(function (response) {
          _this.rewards = response.data.data;
          _this.lastRewards = response.data.data.length;
          console.log(_this.rewards);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    downloadPdf(rewardObject){
      var pdfContent = document.createElement("div");
      pdfContent.setAttribute("id", "addressInformationPdf");
      const address = rewardObject.address;

      const zipIndex = address.search(/\d{5}/);
      const addressLine1 = address.substring(0, zipIndex);
      var addressLine2 = address.substring(zipIndex);
      var addressLine3 = address.includes("  ") ? address.substring(address.indexOf("  ")) : "";

      const addressLine3Checkup = addressLine3.toLowerCase();
      const germanIdentifiers = [
        "de", "deutschland", "germany", "ger"
      ];
      for (let i = 0; i < germanIdentifiers.length; i++) {
        const identifier = germanIdentifiers[i];
        if(addressLine3Checkup == identifier){
          addressLine3 = "";
          break;
        }
      }
      addressLine2 = addressLine2.replace("Deutschland", "").replace("Germany", "").replace("DEUTSCHLAND", "").replace("GERMANY", "");

      var ourAddrContent = document.createElement("p");
      ourAddrContent.style.color = "black";
      ourAddrContent.style.fontSize = "12px";
      ourAddrContent.style.textDecoration = "underline";
      ourAddrContent.style.marginTop = "120px";
      ourAddrContent.style.marginLeft = "80px";
      ourAddrContent.innerHTML = "Enjoy the Street UG (haftungsbeschränkt), Altglienicker Ring 20, 15806 Zossen";
      pdfContent.appendChild(ourAddrContent);

      var addrContent = document.createElement("p");
      addrContent.style.color = "black";
      addrContent.style.marginLeft = "80px";
      addrContent.style.marginTop = "10px";
      addrContent.innerHTML = rewardObject.userName + "<br />" + addressLine1 + "<br />" + addressLine2 + "<br />" + addressLine3;
      pdfContent.appendChild(addrContent);
      

      html2pdf(pdfContent, {
        margin:       1,
        filename: 'Anschrift-Bestellung-' + rewardObject._id + '.pdf',
        html2canvas:  { scrollX: 0, scrollY: 0 }
      });
    },
    searchReward() {
      var _this = this;
      this.$axios
        .post(
          "/reward/history/search/" +
            this.search +
            "/" +
            this.page +
            "/" +
            this.ElementPerPage + '/' + this.getCurrentTimeSpan(), this.tableType
        )
        .then(function (response) {
          _this.rewards = response.data.data;
          _this.lastRewards = _this.rewards.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    sendReward(rewardObject) {
        let _this = this;
        const rewardToSend = {
            rewardHistoryId: rewardObject._id,
            rewardSent: true,
        }

        this.$axios.post('/reward/history/status/update', rewardToSend).then(function(response) {
            _this.fetchRewards();
        }).catch(function(error){
            console.error(error);
          });
    },
    setTimePicker(timeSpan) {
      console.log(timeSpan);
           let currentId = 'dropdown' + this.currentTimeSpan;
           let newId = 'dropdown' + timeSpan;

           document.getElementById(currentId).classList.remove('active');
           document.getElementById(newId).classList.add('active');

           this.currentTimeSpan = timeSpan;
           this.setCurrentTimeSpan(timeSpan);
       },
    setCurrentTimeSpan(timeSpan) {
      this.currentTimeSpan = timeSpan;
      this.fetchRewards();
    },
    getCurrentTimeSpan() {
      let time;
      if(this.currentTimeSpan == 'Today') {
          time = 1;
      } else if (this.currentTimeSpan == 'LastWeek') {
          time = 7;
      } else if (this.currentTimeSpan == 'LastMonth') {
          time = 30;
      } else if (this.currentTimeSpan == 'AllTime') {
          time = -1;
      }

      return time;
    },
    setTableType(type) {
      this.tableType.rewardSent = type;
      this.fetchRewards();
    },
    openTrackingDialog(rewardObject) {
      this.editOrder = rewardObject;
      if(!this.editOrder.carrier) {
        this.editOrder.carrier = "dhl";
      }
      this.trackingDialog = true;
    },
    updateOrder(editOrder) {
      const body = {
        rewardHistoryId: editOrder._id,
        trackingNumber: editOrder.trackingNumber,
        carrier: editOrder.carrier,
      }

      this.$axios.post('/reward/history/updateTracking', body).then((response) => {
        if(response.data.success) {
          this.trackingDialog = false;
        }
      }).catch((error) => {
        this.$showCommonError();
      })
    },
    async copyToClipboard(order) {
      const adress = order.address;
      let formattedAdress;
      if(adress.includes(',')) {
        let split = adress.split(', ');
        formattedAdress = split.join('\n').replace(',', ' ')
      } else {
        let split = adress.split(' ');
        let country = split[split.length - 1];
        let subs = adress.substring(0, adress.length - country.length);
        const zipIndex = adress.search(/\d{5}/);
        let street = subs.substring(0, zipIndex);
        let city = subs.substring(zipIndex);

        formattedAdress = street + '\n' + city + '\n' + country;
      }
      let mytext = order.userName + '\n' + formattedAdress;
      try {
        await navigator.clipboard.writeText(mytext);
        // alert('Copied');
        this.$noty.success("In die Zwischenablage kopiert")
      } catch($e) {
        this.$noty.error("In die Zwischenablage kopiert")
      }
    }
   },
};


</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>